<style scoped>
    #spage {
        background-color: #e8f0fe;
        border-radius: 7px;
        padding: 7px;
        margin: 7px;
        z-index: 10;
        max-width: 200px;
    }
    .q-splitter__panel {
        z-index: 1 !important;
        
    }
    #telo {
        max-width: calc(100% - 220px) !important;
        /*max-height: calc(100% - 50rem) !important;*/
        height: calc(100vh - 200px) !important; /* 99 + 48 + 41= 198 */
    }
    
    h5,h6 {
        margin: 0px;
        margin-block: 0px;
    }

    .right-align { align-self: flex-end; }
    .tab-right { margin-left: auto; }

    .main {
        height: calc(100vh - 151px);
    }

    .ppage {
        height: 100%; 
        display: flex; 
        flex-direction: column;
    }
    .pscrollrow {
        flex: 1;
        overflow-y: auto;
    }

    .pnoscrollrow {
        flex: 1;
        overflow-y: hidden;
    }

    .plistXXX {
        overflow-y: auto; 
        height: auto;
    }

    .pinputrow {
        height: 200px;
    }
    .pbtnrow {
        display: flex;
        justify-content: flex-start;
    }
    .active {
        background-color: #e8f0fe;
    }
    .q-item--active {
        background-color: #eaf1a3;
    }

</style>
<template>
    <q-page class="main">
        <q-page-sticky position="top-right" :offset="[25,125]" id="spage" v-if="getSelVpr!='' || getSelTocka.hasOwnProperty('name')">
            <!-- checkbox for filter -->
            <!-- p v-if="getSelVpr">{{ $gettext("Question") }}: {{ getVprasanje(getSelVpr) }}</p>
            <div v-if="getSelTocka.hasOwnProperty('name')">
                <p>{{ $gettext("Point") }}: {{ getSelTocka.name }} {{ getSelTocka.desc }} ({{ getSelTocka.id }})</p>
                <p>{{ $gettext("Coordinates") }}: {{ getNewAnswer.coordinates.wgs.x }}, {{ getNewAnswer.coordinates.wgs.y }}</p>
                <p>{{ getZapis }}</p>
            </div -->
            <p v-if="getSelVpr">{{ getVprasanje(getSelVpr) }}</p>
            <div v-if="getSelTocka.hasOwnProperty('name')">
                <p>{{ getSelTocka.name }} {{ getSelTocka.desc }}</p>
                <small>({{ getNewAnswer.coordinates.wgs.x }}, {{ getNewAnswer.coordinates.wgs.y }})</small>
                <p>{{ getZapis }}</p>
            </div>
            <q-separator />
            <q-form dense>
                <q-checkbox v-if="getSelVpr" 
                    v-model="getNewAnswer.onlyVpr" class="checkbox-label" color="primary" 
                    :label="$gettext('Limit to selected question')" 
                    off-size="xl" 
                />

                <q-checkbox v-if="getSelTocka.hasOwnProperty('name')"
                    v-model="getNewAnswer.onlyTocka" class="checkbox-label" color="primary" 
                    :label="$gettext('Limit to selected point')" 
                    off-size="xl" 
                />
            </q-form>
        </q-page-sticky>

        <q-tabs v-model="tab" narrow-indicator align="left" @update:modelValue="onTabChange">
            <q-tab name="tocke" :label='$gettext("Points")' />
            <q-tab v-if="true" name="zapisi" :label='$gettext("Notations")' />
            <q-tab v-if="true" name="priloge" :label='$gettext("Attachments")' />
            <q-tab v-if="true" name="povezave" :label='$gettext("Links")' />
            <q-tab v-if="hasSelKraj()" name="viri" :label='$gettext("Sources")' />
            <q-tab v-if="hasSelKraj()" name="wiki" :label='$gettext("Wikipedia")' />
            <q-tab name="informant" :label='$gettext("Informant")' class="tab-right"/>
        </q-tabs>
        <q-tab-panels
                v-model="tab"
                animated
                swipeable
                vertical
                transition-prev="fade"
                transition-next="fade"
                id="telo"
            > 

            <q-tab-panel name="tocke" > <!-- v-if="getSeznamTock.length>0" -->
                <q-splitter 
                    v-model="splitterModel" 
                    horizontal 
                    separator-style="background-color: #e8f0fe;" 
                    before-class="tocglava" 
                    after-class="toctelo"
                    style=""
                >
                <template v-slot:before>
                    <q-list
                    bordered
                    separator
                    class="q-mb-md"
                    v-if="getSeznamTock.length>0"
                    :label='point_list_label'
                    >
                    <q-item
                        v-for="tocka,index in getSeznamTock"
                        :key="index"
                        clickable
                        v-ripple
                        @click="onTockaNaSeznamu(tocka)"
                        :active="tocka.name == getSelTocka.name"
                    >
                        <q-item-section>
                        <q-item-label>{{ tocka.name }}</q-item-label>
                        <q-item-label caption>{{ tocka.place }}</q-item-label>
                        </q-item-section>
                        <q-item-section side>
                        <q-btn
                            flat
                            round
                            dense
                            icon="delete"
                            color="negative"
                            @click="onTockaIzSeznama(tocka)"
                        />
                        </q-item-section>
                    </q-item>
                    </q-list>
                </template>
                <q-separator />
                <template v-slot:after>
                    <div v-if="canNovaTocka || canNaSeznamTocka || canUrediTocka">
                        <!-- p>{{ $gettext("Selected point") }}: {{ getSelTocka.name }} {{ getSelTocka.desc }}</p>
                        <p>{{ $gettext("Coordinates") }}: {{ getNewAnswer.coordinates.wgs.x }}, {{ getNewAnswer.coordinates.wgs.y }}</p -->

                        <q-btn :label='$gettext("Add point to list")' @click="onTockaNaSeznam({id:getSelTocka.id,name:getSelTocka.name, desc:getSelTocka.desc})" v-if="canNaSeznamTocka" />
                        <q-btn :label='$gettext("New point")' @click="switchDodaj()" v-if="canNovaTocka" :class="showDodaj&&!showUredi?'active':''"/>
                        <q-btn :label='$gettext("Edit point")' @click="switchUredi()" v-if="canUrediTocka" :class="showUredi&&!showDodaj?'active':''"/>

                        <q-slide-transition>
                        <div class="q-pa-md form-bg"  v-if="showDodaj">
                            <!-- q-separator />
                            <h6 class="q-placeholder coor-paragraph">{{ $gettext("New point") }}</h6 -->
                            <q-form
                            @submit = "onDodajTocko({ 
                                    name: getNewAnswer.tocka,
                                    dianame: getSelTocka.name, 
                                    place: getNewAnswer.place, 
                                    lon: getNewAnswer.coordinates.wgs.x, 
                                    lat: getNewAnswer.coordinates.wgs.y 
                                    })"
                            @reset="onReset"
                            dense
                            class="q-gutter-md"
                            >
                                <q-input v-model="getNewAnswer.tocka" :label='$gettext("Label")' />
                                <q-input v-model="getNewAnswer.place" :label='$gettext("Place")' dense />
                                <div>
                                    <q-btn :label='$gettext("Add")' type="submit" />
                                    <q-btn :label='$gettext("Cancel")' type="reset" flat class="q-ml-sm hide" />
                                </div>
                            </q-form>
                        </div>
                        <div class="q-pa-md form-bg"  v-if="showUredi">
                            <!-- q-separator />
                            <h6 class="q-placeholder coor-paragraph">{{ $gettext("Edit point") }}</h6 -->
                            <q-form
                            @submit = "onUrediTocko({ 
                                    id: getSelTocka.id,
                                    name: getNewAnswer.tocka,
                                    place: getNewAnswer.place
                                    })"
                            @reset="onReset"
                            dense
                            class="q-gutter-md"
                            >
                                <q-input v-model="getNewAnswer.tocka" :label='$gettext("Label")' />
                                <q-input v-model="getNewAnswer.place" :label='$gettext("Place")' dense />
                                <div>
                                    <q-btn :label='$gettext("Submit")' type="submit" />
                                    <q-btn :label='$gettext("Cancel")' type="reset" flat class="q-ml-sm hide" />
                                </div>
                            </q-form>
                        </div>
                        </q-slide-transition>
                    </div>
                    <div v-else>
                        <p>{{ $gettext("To add a point, select it on the map.") }}</p>
                    </div>
                </template>
                </q-splitter>
            </q-tab-panel>
            <q-tab-panel name="zapisi">
                <div class="col-md-12 ppage">
                    <div class="row-md-12 pscrollrow"  style="overflow: auto; flex-grow: 1;">
                            <q-list
                            bordered
                        separator
                        class="q-mb-md plist"
                        v-if="getOnlyZapisi.length>0"
                        :label='sources_list_label'
                        style=""
                            >
                                <q-item-label header v-if="getOnlyZapisi.length == 0">{{ $gettext("No notations.") }}:</q-item-label>

                                <q-item 
                                    clickable 
                                    v-for="(item, index) in getOnlyZapisi" 
                                    :key="index"  
                                    :data-id="item.id"
                                    @click="onZapisNaSeznamu(item.id, item.zapis, item.fzapis)"
                                    :active="item.id == getNewAnswer.id"
                                    >

                                    <q-item-section>
                                    <q-item-label lines="1">{{item.zapis}} / {{item.fzapis}}</q-item-label>
                                    <!-- q-item-label caption>{{item.fzapis}}</q-item-label -->
                                    <q-item-label caption>{{ item.vprasanje }}/{{ item.tocka }}</q-item-label>
                                    </q-item-section>

                                    <q-item-section side>
                                    <q-btn round flat icon="delete" color="negative" @click="onZapisIzSeznama(item, index)"/>
                                    </q-item-section>
                                </q-item>
                            </q-list>
                    </div> <!-- row -->
                    <q-separator />
                    <div class="pinputrow" style="min-height: 300px; overflow: auto;" v-if="showDodaj || showUredi">
                        <h6 v-if="showDodaj" class="q-placeholder coor-paragraph">{{ $gettext("New notation") }}</h6>
                        <h6 v-else-if="showUredi" class="q-placeholder coor-paragraph">{{ $gettext("Edit notation") }}</h6>
                        <q-form
                            @submit = "onSubmitZapis({ 
                                    id: getNewAnswer.id,
                                    zapis: getNewAnswer.nonPhonetic,
                                    fzapis: getNewAnswer.phonetic,
                                    tocka_id: getSelTocka.id,
                                    tocka: getSelTocka.name,
                                    vprasanje: getSelVpr,
                                    isSpeaker: getNewAnswer.isSpeaker,
                                    isnew: showDodaj
                                    })"
                            dense
                            class="q-gutter-md"
                            >
                            <q-input
                                v-model="getNewAnswer.nonPhonetic"
                                @change="nonPhonetic(getNewAnswer.nonPhonetic)"
                                :label="$gettext('Notation')"
                                lazy-rules
                                disabledrules="[(val) => (val && val.length > 0) || 'This field is required.']"
                                :dense="dense"
                            />
                            <q-input
                                v-model="getNewAnswer.phonetic"
                                @change="phonetic(getNewAnswer.phonetic)"
                                :label="$gettext('Phonetic notation')"
                                lazy-rules
                                disabledrules="[(val) => (val && val.length > 0) || 'This field is required.']"
                                :dense="dense"
                            />
                            <!-- q-checkbox
                                v-model="getNewAnswer.isSpeaker"
                                class="checkbox-label"
                                color="primary"
                                :label="$gettext('Are you a user/speaker of the word?')"
                                off_size="xl"
                                @click="checkAudio(), isSpeaker(getNewAnswer.isSpeaker)"
                            / -->
                            <q-slide-transition>
                                <div v-if="getNewAnswer.isSpeaker && getNewAnswer.id!=null">
                                    <!-- p class="q-placeholder coor-paragraph">{{ $gettext("Pronunciation recording") }}</p -->
                                    <q-toolbar>
                                        <q-btn :label='$gettext("Submit")' type="submit" />
                                        <q-space />
                                        <div v-if="!progress && !getNewAnswer.audio">{{ $gettext("Are you a user/speaker of the word?") + "&nbsp;" }}</div>
                                        <q-btn-group flat>
                                            <q-btn
                                                icon="mic" :loading="progress" color="primary" @click="startRecording()"
                                            >
                                                <template v-slot:loading><q-spinner-audio color="white" /></template>
                                            </q-btn>
                                            <q-btn v-if="progress"
                                                icon="stop" :disable="!progress" color="negative" class="q-mr-sm" @click="stopRecording()"                                        
                                            />
                                            <q-btn v-if="getNewAnswer.audio"
                                                icon="play_arrow" :disable="!getNewAnswer.audio" color="primary" class="q-ml-sm" @click="playRecording()"
                                            />
                                            <q-btn v-if="getNewAnswer.audio"
                                                icon="delete" :disable="!getNewAnswer.audio" color="negative" @click="deleteRecording()"
                                            />
                                        </q-btn-group>
                                        <!-- q-span v-if="!progress && !getNewAnswer.audio">{{ $gettext("Are you a user/speaker of the word?") }}</q-span>
                                        <q-separator vertical inset />
                                        <q-btn
                                            icon="mic" :loading="progress" color="primary" @click="startRecording()"
                                        >
                                            <template v-slot:loading><q-spinner-audio color="white" /></template>
                                        </q-btn>
                                        <q-btn v-if="progress"
                                            icon="stop" :disable="!progress" color="negative" class="q-mr-sm" @click="stopRecording()"                                        
                                        />
                                        <q-separator vertical inset />
                                        <q-btn v-if="getNewAnswer.audio"
                                            icon="play_arrow" :disable="!getNewAnswer.audio" color="primary" class="q-ml-sm" @click="playRecording()"
                                        />
                                        <q-btn v-if="getNewAnswer.audio"
                                            icon="delete" :disable="!getNewAnswer.audio" color="negative" @click="deleteRecording()"
                                        / -->
                                    </q-toolbar>
                                </div>
                                <div v-else>
                                    <q-toolbar>
                                        <q-btn :label='$gettext("Submit")' type="submit" />
                                    </q-toolbar>
                            </div>
                            </q-slide-transition>
                            <div>
                            </div>
                        </q-form>
                    </div>
                    <q-separator />
                    <div class="pbtnrow" >
                        <q-btn v-if="hasSelData()" :label='$gettext("Add")' @click="switchDodaj()" :class="showDodaj?'active':''"/>
                        <q-btn v-if="showUredi" :label='$gettext("Edit")' @click="switchUredi()" :class="showUredi?'active':''"/>
                    </div>
                </div>
            </q-tab-panel>
            <q-tab-panel name="priloge" v-if="getOnlyPriloge.length < 9999"> <!-- starts refresh computed -->
                <div class="col-md-12 ppage">
                    <div class="row-md-12 pscrollrow"  style="overflow: auto; flex-grow: 1;">
                        <q-list
                        bordered
                    separator
                    class="q-mb-md plist"
                    v-if="getOnlyPriloge.length>0"
                    :label='attachment_list_label'
                    style=""
                        >
                        <!-- q-item-label header v-if="getSeznamPrilog.length > 0">{{ $gettext("Attachments") }}:</q-item-label>
                        <q-item-label header v-else>Ni prilog.</q-item-label -->

                        <q-item-label header v-if="getOnlyPriloge.length == 0">{{ $gettext("No attachments.") }}:</q-item-label>

                        <q-item clickable v-for="(item, index) in getOnlyPriloge" 
                            @click="onPrilogaNaSeznamu(item)"
                            :active="item.id == priloga.id"
                            :key="index">
                            <q-item-section side>
                                <q-btn flat round dense
                                    icon="download"
                                    color="primary"
                                    @click="downloadFile(item)"
                                />
                            </q-item-section>

                            <q-item-section avatar top>
                            <!-- q-avatar color="primary" text-color="white">{{item.name.split('.')[item.name.split('.').length - 1]}}</q-avatar -->
                            <q-avatar color="primary" text-color="white">{{item.ext}}</q-avatar>
                            </q-item-section>

                            <q-item-section>
                            <q-item-label lines="1">{{item.name}}</q-item-label><!--  / {{item.rpath}} -->
                            <!-- q-item-label caption>{{(item.size / (1024*1024)).toFixed(2)}} MB</q-item-label -->
                            <q-item-label caption>{{ item.vprasanje }}/{{ item.tocka }}</q-item-label>
                            </q-item-section>

                            <q-item-section side>
                            <q-btn round flat icon="delete" color="negative" @click="onPrilogaIzSeznama(item, index)"/>
                            </q-item-section>
                        </q-item>
                        </q-list>
                    </div> <!-- row -->
                    <q-separator />

                    <div class="pinputrow" style="min-height: 200px; overflow: auto;" v-if="showDodaj">
                        <div class="q-gutter-sm row items-start">
                            <q-uploader
                                url="/user/upload"
                                :label= "$gettext('Pick files')"
                                multiple
                                batch
                                style=""
                                :headers="[{name:'Authorization', value:'Bearer ' + $store.getters.accessToken}]"
                                @finish="fetchVsePriloge"
                            />
                        </div>                                           
                    </div>
                    <div class="q-pa-md" v-if="showUredi">
                            <h6 class="q-placeholder coor-paragraph">{{ $gettext("Edit attachment") }}</h6>
                            <q-form
                            @submit = "onUrediPrilogo({ 
                                    id: priloga.id,
                                    name: priloga.name,
                                    tocka_id: getSelTocka.id, 
                                    tocka: getSelTocka.name,
                                    vprasanje: getSelVpr
                                    })"
                            dense
                            class="q-gutter-md"
                            >
                                <q-input v-model="priloga.name" :label='$gettext("Name")' />
                                <q-checkbox v-if="getSelVpr" 
                                    v-model="priloga.setvpr" class="checkbox-label" color="primary" 
                                    :label="print($gettext('Assign to %s.'), getSelVpr)" 
                                    off-size="xl" 
                                />

                                <q-checkbox v-if="getSelTocka.hasOwnProperty('name')"
                                    v-model="priloga.settocka" class="checkbox-label" color="primary" 
                                    :label="print($gettext('Assign to %s.'), getSelTocka.name)" 
                                    off-size="xl" 
                                />
                                <!-- p v-if="priloga.setvpr || priloga.settocka">
                                    {{ print($gettext("Set %s/%s"), getSelVpr, getSelTocka?getSelTocka.name:"") }}
                                </p -->

                                <div>
                                    <q-btn :label='$gettext("Submit")' type="submit" />
                                </div>
                            </q-form>
                        </div>

                    <q-separator />
                    <div class="pbtnrow" >
                        <q-btn :label='$gettext("Add")' @click="switchDodaj()"  :class="showDodaj?'active':''"/>
                        <q-btn :label='$gettext("Edit")' @click="switchUredi()" :class="showUredi?'active':''" v-if="priloga.id"/>
                    </div>
                </div> <!-- col-md-12 -->
            </q-tab-panel>
            <q-tab-panel name="povezave" v-if="getOnlyPovezave.length < 9999" style="">
                <div class="col-md-12 ppage">
                        <div class="row-md-12 pscrollrow">
                            <q-list
                    bordered
                    separator
                    class="q-mb-md plist"
                    v-if="getOnlyPovezave.length>0"
                    :label='link_list_label'
                    style=""
                    >
                        <q-item
                            v-for="link,index in getOnlyPovezave"
                            :key="index"
                            clickable
                            v-ripple
                            @click="onPovezavaNaSeznamu(link)"
                            :active="link.id == povezava_id"
                        >
                            <q-item-section side>
                            <q-btn
                                flat
                                round
                                dense
                                icon="open_in_new"
                                color="primary"
                                @click="openVir(link.povezava)"
                            />
                            </q-item-section>
                            <q-item-section>
                            <q-item-label>{{ link.povezava }}</q-item-label>
                            <q-item-label caption>{{ link.vprasanje }}/{{ link.tocka }}</q-item-label>
                            </q-item-section>
                            <q-item-section side>
                            <q-btn
                                flat
                                round
                                dense
                                icon="delete"
                                color="negative"
                                @click="onPovezavaIzSeznama(link)"
                            />
                            </q-item-section>
                        </q-item>
                    </q-list>
                    </div> <!-- row -->
                    <q-separator />
                    <div class="row-md-12 pinputrow">
                        <div class="q-pa-md" v-if="!showUredi && showDodaj ">
                            <h6 class="q-placeholder coor-paragraph">{{ $gettext("New link") }}</h6>
                            <q-form
                            @submit = "onDodajPovezavo({ 
                                    povezava: povezava,
                                    tocka_id: getSelTocka.id, 
                                    tocka: getSelTocka.name,
                                    vprasanje: getSelVpr
                                    })"
                            dense
                            class="q-gutter-md"
                            >
                                <q-input v-model="povezava" :label='$gettext("Link")' />
                                <div>
                                    <q-btn :label='$gettext("Add")' type="submit" />
                                </div>
                            </q-form>
                        </div>
                        <div class="q-pa-md" v-if="showUredi">
                            <h6 class="q-placeholder coor-paragraph">{{ $gettext("Edit link") }}</h6>
                            <q-form
                            @submit = "onUrediPovezavo({ 
                                    id: povezava_id,
                                    povezava: povezava,
                                    tocka_id: getSelTocka.id, 
                                    tocka: getSelTocka.name,
                                    vprasanje: getSelVpr
                                    })"
                            dense
                            class="q-gutter-md"
                            >
                                <q-input v-model="povezava" :label='$gettext("Link")' />
                                <div>
                                    <q-btn :label='$gettext("Submit")' type="submit" />
                                </div>
                            </q-form>
                        </div>
                    </div> <!-- row -->
                    <q-separator />
                    <div class="pbtnrow" >
                        <q-btn :label='$gettext("Add")' @click="switchDodaj()" :class="showDodaj?'active':''"/>
                        <q-btn :label='$gettext("Edit")' @click="switchUredi()" :class="showUredi?'active':''" v-if="povezava_id"/>
                    </div>
                </div> <!-- col-md-12 -->
            </q-tab-panel>
            <q-tab-panel name="wiki">
                <div class="col-md-12 ppage">
                    <div class="row-md-12 pnoscrollrow">
                        <!-- open iframe with wiki url mobile --> <!-- 'https://'+language+'.m.wikipedia.org/wiki/' + getSelTocka.desc -->
                        <iframe
                            width="100%"
                            height="100%"
                            :src="wiki_url" 
                            frameborder="0"
                            allowfullscreen
                        ></iframe>
                    </div> <!-- row -->
                </div> <!-- col-md-12 -->
            </q-tab-panel>
            <q-tab-panel name="viri">
                <div class="col-md-12 ppage">
                    <div class="row-md-12 pscrollrow">
                        <q-list
                    bordered
                    separator
                    class="q-mb-md plist"
                    v-if="getViri.length>0"
                    :label='sources_list_label'
                    style=""
                    >
                        <q-item
                            v-for="vir,index in getViri"
                            :key="index"
                            clickable
                            v-ripple
                            @click="onVirNaSeznamu(vir)"
                        >
                        <q-item-section side>
                                <q-btn
                                    flat
                                    round
                                    dense
                                    icon="open_in_new"
                                    color="primary"
                                    @click="openVir(vir.url)"
                                />
                            </q-item-section>

                        <q-item-section>
                            <q-item-label>{{ vir.vir }}</q-item-label>
                            <q-item-label caption>{{ vir.url }}</q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    </div> <!-- row -->
                </div> <!-- col-md-12 -->
            </q-tab-panel>
            <q-tab-panel name="informant">
                <div class="col ppage">
                    <q-form
                        @submit = "onSubmitInformer({ 
                                age: getNewAnswer.age,
                                sex: getNewAnswer.sex,
                                residence: getNewAnswer.residence
                            })"
                        dense
                        class="q-gutter-md"
                    >
                        <h6 class="q-pa-md">{{ $gettext("Informant") + ": " + getNewAnswer.name }}</h6>
                        <div class="row q-pa-md">
                            <q-input
                                v-model="getNewAnswer.age"
                                @change="age(getNewAnswer.age)"
                                type="number"
                                :label="$gettext('Age')"
                                lazy-rules
                                off_rules="[(val) => (val && val.length > 0) || $gettext('This field is required.')]"
                                :dense="dense"
                            />
                            <q-space />
                            <div class="q-placeholder coor-paragraph">{{ $gettext("Sex") }}</div>
                            <div class="q-gutter-sm">
                                <q-radio
                                    v-model="getNewAnswer.sex"
                                    checked-icon="not_interested"
                                    unchecked-icon="panorama_fish_eye"
                                    label=""
                                    val = ""
                                />
                                <q-radio
                                    v-model="getNewAnswer.sex"
                                    checked-icon="task_alt" 
                                    unchecked-icon="panorama_fish_eye"
                                    :label="$gettext('Female')"
                                    val = "female"
                                />
                                <q-radio
                                    v-model="getNewAnswer.sex"
                                    checked-icon="task_alt"
                                    unchecked-icon="panorama_fish_eye"
                                    :label="$gettext('Male')"
                                    val = "male"
                                />
                            </div>
                        </div>

                        <div class="row q-pa-md">
                            <!-- q-input
                                v-model="getNewAnswer.education"
                                @change="education(getNewAnswer.education)"
                                :label="$gettext('Education')"
                                lazy-rules
                                off_rules="[(val) => (val && val.length > 0) || $gettext('This field is required.')]"
                                :dense="dense"
                            />
                            <q-space />
                            <div class="q-placeholder coor-paragraph">{{ $gettext("Residence") }}</div>
                            <div class="q-gutter-sm">
                                <q-radio
                                    v-model="getNewAnswer.residence"
                                    checked-icon="not_interested"
                                    unchecked-icon="panorama_fish_eye"
                                    label=""
                                    val = ""
                                />
                                <q-radio
                                    v-model="getNewAnswer.residence"
                                    checked-icon="task_alt" 
                                    unchecked-icon="panorama_fish_eye"
                                    :label="$gettext('Urban')"
                                    val = "urban"
                                />
                                <q-radio
                                    v-model="getNewAnswer.residence"
                                    checked-icon="task_alt"
                                    unchecked-icon="panorama_fish_eye"
                                    :label="$gettext('Rural')"
                                    val = "rural"
                                />
                            </div -->
                            <q-input
                                v-model="getNewAnswer.residence"
                                @change="residence(getNewAnswer.residence)"
                                :label="$gettext('Place of residence')"
                                lazy-rules
                                off_rules="[(val) => (val && val.length > 0) || $gettext('This field is required.') ]"
                                :dense="dense"
                            />
                            <!-- q-input
                                v-model="getNewAnswer.id"
                                @change="id(getNewAnswer.id)"
                                :label="$gettext('Identifier')"
                                lazy-rules
                                off_rules="[(val) => (val && val.length > 0) || $gettext('This field is required.') ]"
                                :dense="dense"
                            / -->
                        </div>
                        <q-toolbar>
                            <q-btn :label='$gettext("Submit")' type="submit" />
                        </q-toolbar>
                    </q-form>
                </div> <!-- col-md-12 -->
            </q-tab-panel>
        </q-tab-panels>
    </q-page>
</template>

<script>
import { ref } from "vue";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { useQuasar } from "quasar";
import store from '../store';

export default {
    name: "InputContainer",
    components: {},
    data() {
        return {
            dense: ref(true),
            progress: ref(false),
            mediaRecorder: ref(null),
            chunks: ref([]),
            timeout: ref(5),
            tab: ref("tocke"),
            splitterModel: ref(0),
            //name: ref(""),
            //place: ref(""),
            povezava: ref(""),
            povezava_id: ref(0),
            priloga: { 'id': ref(0), 'name':ref(''), 'setvpr': ref(false), 'settocka': ref(false) },
            showDodaj: ref(false),
            showUredi: ref(false),
            panel_style: ref(""),
            language: ref('sl'), //useQuasar().lang.isoName,

            $q: useQuasar(),
        };
    },

    computed: {
        ...mapGetters(["getNewAnswer", "getIzbTocka", "getSelVpr", "getSelOdg", "getSelTocka", "getZapis", 
                       "getVprasanje", "getSeznamTock", "getSeznamPovezav", "getSeznamPrilog", "getViri", "getWiki",
                       "getSeznamZapisov"]),
        _isKnown() {
            var isKnown = this.getSelTocka && !!this.getSelTocka.name;
            if (isKnown) {
                var id = this.getSelTocka.id;
                if( typeof id == 'string' ) {
                    if(!id.startsWith("C"))
                        isKnown = false;
                } else if( id < 1 ) {
                    isKnown = false;
                }
            }
            if(isKnown) {
                this.getNewAnswer.tocka = this.getSelTocka.name;
                this.getNewAnswer.place = this.getSelTocka.desc;
            }
            return isKnown;
        },
        canNovaTocka() {
            var haveGeo = !!this.getNewAnswer.coordinates.wgs.x && !!this.getNewAnswer.coordinates.wgs.y;
            var notKnown = !this._isKnown;
            console.log("canNovaTocka: " + haveGeo + " " + notKnown);
            return haveGeo && notKnown;
        },
        /*
        getSeznamVirov() {
            console.log("getSeznamVirov - IC: " + this.getSelTocka.name);
            return store.getters.getSeznamVirov;
        },
        */
        canNaSeznamTocka() {
            //var haveGeo = !!this.getNewAnswer.coordinates.wgs.x && !!this.getNewAnswer.coordinates.wgs.y;
            var isKnown = this._isKnown;
            var isNotOnList = !this.getSeznamTock.some((tocka) => tocka.name == this.getSelTocka.name);

            console.log("canNaSeznamTocka: " /*+ haveGeo + " " */+ isKnown + " " + isNotOnList);
            return /*haveGeo &&*/ isKnown && isNotOnList;
        },
        canUrediTocka() {
            var haveGeo = !!this.getNewAnswer.coordinates.wgs.x && !!this.getNewAnswer.coordinates.wgs.y;
            var isKnown = this._isKnown;

            console.log("canUrediTocka: " + haveGeo + " " + isKnown);
            return haveGeo && isKnown;
        },
        point_list_label() {
            var text = this.getSeznamTock.length > 0 ? this.$gettext("List of points") : this.$gettext("Empty list of points.");
            return text;
        },
        link_list_label() {
            var text = this.getSeznamPovezav.length > 0 ? this.$gettext("List of links") : this.$gettext("Empty list of links.");
            return text;
        },
        attachment_list_label() {
            var text = this.getSeznamPrilog.length > 0 ? this.$gettext("List of attachments") : this.$gettext("Empty list of attachments.");
            return text;
        },
        sources_list_label() {
            var text = this.getViri.length > 0 ? this.$gettext("List of sources") : this.$gettext("Empty list of sources.");
            return text;
        },
        wiki_url() {
            // search viri
            /*
            var viri = this.getViri;
            var vir = viri.find((vir) => vir.vir.toLowerCase().includes("wiki"));
            if( vir ) {
                return vir.url;
            }
            */
            if( this.getWiki ) {
                return this.getWiki;
            }
            return 'https://'+this.language+'.m.wikipedia.org/wiki/' + this.getSelTocka.desc;
        },
        getOnlyPriloge() {
            console.log("getOnlyPriloge - IC: " + this.getNewAnswer.onlyTocka + " " + this.getNewAnswer.onlyVpr);
            var onlyTocka = this.getNewAnswer.onlyTocka
            var onlyVpr = this.getNewAnswer.onlyVpr
            var tocka = this.getSelTocka.name || "";
            var vprasanje = this.getSelVpr;
            var priloge = this.getSeznamPrilog;

            if( onlyTocka && onlyVpr ) {
                return priloge.filter((priloga) => priloga.tocka == tocka && priloga.vprasanje == vprasanje);
            } else if( onlyTocka ) {
                return priloge.filter((priloga) => priloga.tocka == tocka);
            } else if( onlyVpr ) {
                return priloge.filter((priloga) => priloga.vprasanje == vprasanje);
            } else {
                return priloge;
            }
        },
        getOnlyPovezave() {
            console.log("getOnlyPovezave - IC: " + this.getNewAnswer.onlyTocka + " " + this.getNewAnswer.onlyVpr);
            var onlyTocka = this.getNewAnswer.onlyTocka
            var onlyVpr = this.getNewAnswer.onlyVpr
            var tocka = this.getSelTocka.name || "";
            var vprasanje = this.getSelVpr;

            if( onlyTocka && onlyVpr ) {
                return this.getSeznamPovezav.filter((povezava) => povezava.tocka == tocka && povezava.vprasanje == vprasanje);
            } else if( onlyTocka ) {
                return this.getSeznamPovezav.filter((povezava) => povezava.tocka == tocka);
            } else if( onlyVpr ) {
                return this.getSeznamPovezav.filter((povezava) => povezava.vprasanje == vprasanje);
            } else {
                return this.getSeznamPovezav;
            }
        },
        getOnlyZapisi() {
            console.log("getOnlyZapisi - IC: " + this.getNewAnswer.onlyTocka + " " + this.getNewAnswer.onlyVpr);
            var onlyTocka = this.getNewAnswer.onlyTocka
            var onlyVpr = this.getNewAnswer.onlyVpr
            var tocka = this.getSelTocka.name || "";
            var vprasanje = this.getSelVpr;

            if( onlyTocka && onlyVpr ) {
                return this.getSeznamZapisov.filter((zapis) => zapis.tocka == tocka && zapis.vprasanje == vprasanje);
            } else if( onlyTocka ) {
                return this.getSeznamZapisov.filter((zapis) => zapis.tocka == tocka);
            } else if( onlyVpr ) {
                return this.getSeznamZapisov.filter((zapis) => zapis.vprasanje == vprasanje);
            } else {
                return this.getSeznamZapisov;
            }
        }

    },

    watch: {
        getSelTocka(tocka) {
            console.log("watch getSelTocka - IC: " + tocka.name);
            store.dispatch("loadViri", tocka.id);
            /*
            deep: true,
            handler(newVal) {
                this.coorX = newVal.coordinates.wgs.x;
                this.coorY = newVal.coordinates.wgs.y;
                this.place(newVal.name);
            },
            */

        },
        getIzbTocka(tocka) {
            console.log("watch getIzbTocka - IC: " + tocka.name);
            store.dispatch("loadViri", tocka.id);
        },
        getSeznamPovezav(povezave) {
            console.log("watch getSeznamPovezav - IC: " + povezave.length);
        },
        getSeznamPrilog(priloge) {
            console.log("watch getSeznamPrilog - IC: " + priloge.length);
        },
        getSeznamZapisov(zapisi) {
            console.log("watch getSeznamZapisov - IC: " + zapisi.length);
        },

    },
    actions: {
        //...mapActions(["selectTockaByName"]),
    },
    methods: {
        ...mapActions(['msgError', 'msgInfo']),
        onReset() {
            console.log("onReset - IC:  why should I?");
        },
        onTabChange(tab) {
            console.log("onTabChange - IC: " + tab);
            this.showDodaj = false;
            this.showUredi = false;
        },
        print(text, ...args) {
            return text.replace(/%s/g, () => args.shift());
        },
        switchDodaj() {
            this.showDodaj = !this.showDodaj;
            if( this.showDodaj ) {
                this.showUredi = false;
                this.getNewAnswer.id = null;
            }
        },
        switchUredi() {
            this.showUredi = !this.showUredi;
            if( this.showUredi )
                this.showDodaj = false;
        },
        switchOnlyVpr() {
            this.getNewAnswer.onlyVpr = !this.getNewAnswer.onlyVpr;
        },
        switchOnlyTocka() {
            this.getNewAnswer.onlyTocka = !this.getNewAnswer.onlyTocka;
        },
        resize_panel() {
            // calculate size of #glava and #panel-dno
            let gh = document.getElementById("glava").offsetHeight;
            let pdh = 0;
            let pvh = 0;
            let pd = document.getElementById("panel-dno");
            if( pd ) {
                pdh = pd.offsetHeight;
            }
            let pv = document.getElementById("panel-vrh");
            if( pv ) {
                pvh = pv.offsetHeight;
            }
            let h = this.$q.screen.height - gh - pdh - 50;

            this.panel_style = "height: " + (h) + "px"; // 99 + 16 + 49 = 164
        },
        hasSelData() {
            //this.showDodaj = false;
            //this.showUredi = false;

            return this.getSelVpr != "" && this.getSelTocka.hasOwnProperty("name");
        },
        hasSelKraj() {
            return this.getSelTocka.hasOwnProperty("name");
        },
        ...mapMutations([
            "setCoordinates",
            "tocka",
            "questionNumber",
            "nonPhonetic",
            "phonetic",
            //"place",
            "isSpeaker",
            "audioUrl",
            "audio",
            //"DodajTocko",
            "TockaNaSeznam",
            "TockaIzSeznama",
            "clearTocka",
            //"SelectTockaByName",
            "selectTockaByName",
            "addPriloge",

        ]),
        ...mapActions(["addFiles", "removeFile","addTocko", "fetchUsersTocke", 
                    "popraviTocko", "addPovezavo", "removePovezavo", "popraviPovezavo", "fetchVsePovezave", 
                    "removePrilogo", "popraviPrilogo", "fetchVsePriloge", "fetchVseZapise"]),


        onSubmitInformer(data) {
            console.log("onSubmitInformer - IC: " + data);
            this.$store.dispatch("submitInformer", data);
        },
        onSubmitZapis(data) {
            console.log("onSubmitZapis - IC: " + data);
            if( typeof data.tocka_id != 'string' || !data.tocka_id.startsWith("C")) {
                data.tocka_id = data.tocka; // diatlasove točke naslavljamo z imenom
            }
            if( data.isnew ) {
                this.$store.dispatch("addZapis", data);
                this.showDodaj = false;
                this.showUredi = true;
            } else {
                this.$store.dispatch("popraviZapis", data);
                this.showUredi = false;
            }

        },
        onZapisNaSeznamu(id, zapis, fzapis) {
            console.log("onZapisNaSeznamu - IC: " + id + " " + zapis + " " + fzapis);
            //this.$store.commit("sSelOdg", {id:id, zapis:zapis, fzapis:fzapis});
            // ignore if it was removed
            if( this.getSeznamZapisov.some((z) => z.id == id) ) {
                this.getNewAnswer.nonPhonetic = zapis;
                this.getNewAnswer.phonetic = fzapis;
                this.getNewAnswer.id = id;
                this.showUredi = true;
                this.showDodaj = false;
            }
        },
        onZapisIzSeznama(zapis, idx) {
            console.log("onZapisIzSeznama - IC: " + zapis);
            // remove from list
            for (var i = 0; i < this.getSeznamZapisov.length; i++) {
                if (this.getSeznamZapisov[i].id == zapis.id) {
                    this.getSeznamZapisov.splice(i, 1);
                    break;
                }
            }
            this.$store.dispatch("removeZapis", {id:zapis.id});
        },
        onTockaNaSeznamu(tocka) {
            console.log("onTockaNaSeznamu - IC: " + tocka);
            //this.onTockaNaSeznam(tocka);
            this.selectTockaByName(tocka.name);
            this.getNewAnswer.tocka = tocka.name;
            this.getNewAnswer.place = tocka.place;

            let st = this.getSelTocka;
            if (st && st.hasOwnProperty("name") && st.name == tocka.name) {
                this.setCoordinates({
                    coordinates: [st.x, st.y],
                    SRS: "EPSG:4326",
                });
            }

        },

        onTockaIzSeznama(tocka) {
            console.log("TockaIzSeznama - IC: " + tocka);
            if (this.getSelTocka.name == tocka.name) {
                this.clearTocka();
            }
            this.TockaIzSeznama(tocka);
        },

        onTockaNaSeznam(tocka) {
            console.log("onTockaNaSeznam - IC: " + tocka);
            this.TockaNaSeznam(tocka);
            //this.$store.dispatch("fetchUserTocke");
        },

        onDodajTocko(tocka) {
            console.log("onDodajTocko - IC: " + tocka);
            this.addTocko(tocka);
            this.showDodaj = false;
        },

        async onDodajPovezavo(povezava) {
            console.log("onDodajPovezavo - IC: " + povezava);
            if( typeof povezava.tocka_id != 'string' || !povezava.tocka_id.startsWith("C")) {
                povezava.tocka_id = povezava.tocka; // diatlasove točke naslavljamo z imenom
            }
            try {
                await this.addPovezavo(povezava);

                this.$store.dispatch("fetchVsePovezave");
                this.povezava = "";
            } catch (err) {
                console.log("onDodajPovezavo - IC: " + err);
                this.msgError(err);
            }
        },

        onPovezavaIzSeznama(povezava) {
            console.log("onPovezavaIzSeznama - IC: " + povezava);
            this.removePovezavo(povezava);
        },

        onPovezavaNaSeznamu(povezava) {
            console.log("onPovezavaNaSeznamu - IC: " + povezava);
            this.povezava = povezava.povezava;
            this.povezava_id = povezava.id;

            // dela bolje brez this.showUredi = ( povezava.tocka == this.getSelTocka.name && povezava.vprasanje == this.getSelVpr );
        },

        onPrilogaNaSeznamu(priloga) {
            console.log("onPrilogaNaSeznamu - IC: " + priloga);
            this.priloga.id = priloga.id;
            this.priloga.name = priloga.name;
            this.priloga.setvpr = false;
            if( this.getSelVpr ) {
                this.priloga.setvpr = true;
            }
            this.priloga.settocka = false;
            if( this.getSelTocka.hasOwnProperty('name') ) {
                this.priloga.settocka = true;
            }
            this.showUredi = true;
        },

        onPrilogaIzSeznama(priloga, idx) {
            console.log("onPrilogaIzSeznama - IC: " + priloga);
            this.removePrilogo({priloga: priloga, idx: idx});
        },

        async onUrediTocko(tocka) {
            console.log("onUrediTocko - IC: " + tocka);
            if( this.popraviTocko(tocka) ) {
                // nekaj ne dela osvežimo seznam iz baze
                //this.$store.dispatch("fetchUserTocke");
                // tu je verjetno napaka ker kažemo vse ne samo user
                await this.fetchVseTocke();

                // correct in getSeznamTock
                var id = tocka.id;
                if( typeof id == 'string' && !id.startsWith("C") ) {
                    id = id.substring(1);
                }
                let utid = 'isla:' + id;
                for (var i = 0; i < this.getSeznamTock.length; i++) {
                    if (this.getSeznamTock[i].id == utid) {
                        this.getSeznamTock[i].name = tocka.name;
                        this.getSeznamTock[i].place = tocka.place;
                        break;
                    }
                }
                //this.$forceUpdate();
                //this.$store.dispatch("fetchUserTocke");
                //store.dispatch("selectTocka",tocka); //commit('selectTocka', {id:id, name:name, desc:desc, x:coord[0], y:coord[1]});
                await this.selectTocka(tocka);

                // osvežimo podatke na vse možne načine
                let gt = this.getSelTocka;
                gt.name = tocka.name;
                gt.desc = tocka.place;
                store.commit("sSelTocka", gt);
                store.commit("sIzbTocka", gt);

                //this.getSelTocka.name = tocka.name;
                //this.getSelTocka.desc = tocka.place;

                if( this.getIzbTocka ) {
                    this.getIzbTocka.name = tocka.name;
                    this.getIzbTocka.desc = tocka.place;
                }

                //this.$forceUpdate();
            }
            this.showUredi = false;
        },

        async onUrediPrilogo(priloga) {
            console.log("onUrediPrilogo - IC: " + priloga + " " + this.priloga);
            if( typeof priloga.tocka_id != 'string' || !priloga.tocka_id.startsWith("C")) {
                priloga.tocka_id = priloga.tocka; // diatlasove točke naslavljamo z imenom
            }
            try {
                if(priloga.tocka_id == null || !this.priloga.settocka) {
                    priloga.tocka_id = -1; // točke uporabnik ni izbral a parameter rabimo in ne sme biti null/undefined
                }
                if(!this.priloga.setvpr) {
                    priloga.vprasanje = "-1";
                }
                await this.popraviPrilogo(priloga);
                // je že v popraviPrilogo this.$store.dispatch("fetchVsePriloge");
                this.showUredi = false;
            } catch (err) {
                console.log("onUrediPrilogo - IC: " + err);
                this.msgError(err);
            }
        },

        async onUrediPovezavo(povezava) {
            console.log("onUrediPovezavo - IC: " + povezava);
            try {
                await this.popraviPovezavo(povezava);
                this.$store.dispatch("fetchVsePovezave");
                this.povezava = "";
                this.povezava_id = 0;
                this.showUredi = false;
            } catch (err) {
                console.log("onUrediPovezavo - IC: " + err);
                this.msgError(err);
            }
        },
        
        downloadFile(priloga) {
            console.log("downloadFile - IC: " + priloga);
            window.open("/uploads/" + priloga.rpath, "_blank");
        },

        openVir(url) {
            console.log("openVir - IC: " + url);
            window.open(url, "_blank");
        },

        onVirNaSeznamu(vir) {
            console.log("onVirNaSeznamu - IC: " + vir);
            this.openVir(vir.url);
        },

        displayNotification(newVal) {
            console.log("input DN", newVal);
            this.msgError(newVal);
            /*
            this.$q.notify({
                position: "center",
                color: "primary",
                message: newVal,
            });
            */
        },

        startRecording() {
            this.deleteRecording();
            if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                this.progress = true;
                setTimeout(() => {
                    this.stopRecording();
                }, 15000);

                navigator.mediaDevices
                .getUserMedia({ audio: true })
                .then((stream) => {
                    console.log("startRecording - IC: " + stream);
                    this.mediaRecorder = new MediaRecorder(stream);
                    //this.mediaRecorder.setOutputFile("test" + getNewAnswer.id + ".webm")
                    this.mediaRecorder.start();
                    this.mediaRecorder.ondataavailable = (e) => {
                        this.chunks.push(e.data);
                        const audioBlob = new Blob(this.chunks, {
                            type: "audio/webm;codecs=opus",
                        });
                        this.aUrl = URL.createObjectURL(audioBlob);
                        this.aud = new Audio(this.aUrl);
                        this.audioUrl(this.aUrl)
                        this.audio(this.aud)
                    };
                    this.mediaRecorder.onstop = () => {
                        console.log("stopRecording - IC: " + this.chunks);
                        const audioBlob = new Blob(this.chunks, {
                            type: "audio/webm;codecs=opus",
                        });
                        this.aUrl = URL.createObjectURL(audioBlob);
                        this.aud = new Audio(this.aUrl);
                        this.getNewAnswer.audio = this.aud;
                        this.getNewAnswer.audioUrl = this.aUrl;
                        this.getNewAnswer.isSpeaker = true;
                        this.getNewAnswer.audioBlob = audioBlob;
                        this.progress = false;
                        store.dispatch("addAudio", {
                            zapis_id: this.getNewAnswer.id,
                            blob: this.getNewAnswer.audioBlob,
                            name: "isla" + this.getNewAnswer.id + ".webm",
                            type: "audio/webm",
                        });
                    };
                })

                // Error callback
                .catch(function (err) {
                    this.displayNotification( $gettext("The following error occurred: ") + err);
                });
            } else {
                this.displayNotification( $gettext("Your browser does not support audio capture!"));
            }
        },

        stopRecording() {
            this.mediaRecorder.stop();
            /*
            this.progress = false;
            //sendAudio();
            //POST to server
            this.getNewAnswer.audio = this.aud;
            this.getNewAnswer.audioUrl = this.aUrl;
            this.getNewAnswer.isSpeaker = true;

            store.dispatch("addAudio", {
                file: this.aud,
                name: "isla" + this.getNewAnswer.id + ".webm",
                type: "audio/webm",
            });
            */
        },

        playRecording() {
            if (this.getNewAnswer.audio) {
                this.getNewAnswer.audio.play();
            }
        },

        checkAudio() {
            if (this.getNewAnswer.audio && !this.getNewAnswer.isSpeaker) {
                this.deleteRecording();
            }
        },

        deleteRecording() {
            this.mediaRecorder = ref(null);
            this.chunks = ref([]);
            this.aUrl = null;
            this.aud = null;
            this.audioUrl(null);
            this.audio(null);
        },
    },



    
    mounted() {
        console.log("mounted input");
        // load tocke
        // premaknjeno v App this.$store.dispatch("fetchUserTocke");
        //var test = this.getSeznamPrilog;
        //this.resize_panel();
    },
    /*
    activated() {
        console.log("activated input");
    },
    deactivated() {
        console.log("deactivated input");
    },
*/
};

</script>
