
import axios from "axios";
import store from "../index";
import router from "@/router/index";
import { ref, computed, nextTick, isProxy, toRaw  } from "vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { addIfNotIn } from "@/store/modules/func.js";


var DIASERVER = "/cisla";

if (process.env.NODE_ENV === 'development') {
    DIASERVER = "http://diatlas/cisla"
    //DIASERVER = "http://localhost:8888/cisla"
    // premaknjeno v dodatni nginx
}
  

const state = {
    sSelvpr: ref(''),
    sSelOdg: ref(''),
    sSelTocka: ref({}),
    sZapis: ref(""), // kadar so izbrani vsi trije (vpr, odg, tocka)

    sDetails: ref({}),
    sVprs: ref([]),
    sViri: ref([]),
    sWiki: '',
    sGradivo: ref([]), // za prikaz gradiva
};

var diaaxi = axios.create({
    baseURL: DIASERVER,//'http://localhost/cisla',
    timeout: 10000,
    accept: 'application/json',
    auth: {
        username: 'jure',
        password: '1'
      }
});

const getters = {
    getSelVpr: state => state.sSelvpr,
    getSelOdg: state => state.sSelOdg,
    getSelTocka: state => state.sSelTocka,
    getZapis: state => state.sZapis,
    getDetails: state => state.sDetails,
    getVprs: state => state.sVprs,
    getViri: state => state.sViri,
    getWiki: state => state.sWiki,
    getGradivo: state => state.sGradivo,
    getOdgZapisi(state, getters) {
        var odg = getters.getSelOdg;
        if(odg == "undefined") {
            return [];
        }
        var odg_id = odg.id;
        var zapisi = [];
        for( var i=0; i<state.sDetails.zapisi.length; i++) {
            var zap = state.sDetails.zapisi[i];
            if(zap.odg_id == odg_id) {
                zapisi.push(zap);
            }
        }
        return zapisi;
    },
    getOdgId: state => oIme => {
        for( var i=0; i<state.sDetails.odgs.length; i++) {
            var odg = state.sDetails.odgs[i];
            if(odg.ime == oIme) {
                return odg.id;
            }
        }
        return 0;
    },
    getVprasanje: state => vpr_ime => {
        var vpr = state.sVprs.find(vpr => vpr.ime == vpr_ime);
        return vpr_ime + " - " + vpr.opis;
    },
    getVprOpis: state => vpr_ime => {
        var vpr = state.sVprs.find(vpr => vpr.ime == vpr_ime);
        if(!vpr) return "";
        return vpr.opis;
    },
    getTockaOpis: state => tocka_id => {
        var tocka = state.sDetails.mreza.find(tocka => tocka.id == tocka_id);
        if(!tocka) return "";
        return tocka.opis;
    }

};

const mutations = {
    sVprs: (state, input) => { state.sVprs = input; },
    sDetails: (state, newvpr) => { state.sDetails = newvpr; },
    sViri: (state, newv) => { 
        /*
        // find wiki
        var wiki = ''
        var vir = newv.find((vir) => vir.vir.toLowerCase().includes("wiki"));
        if( vir ) {
            wiki = vir.url;
        }
        state.sWiki = wiki;
        */

        // move "Fonološki opis" to the top
        var fon = newv.find((vir) => vir.vir.toLowerCase().includes("fonološki opis"));
        if( fon ) {
            var idx = newv.indexOf(fon);
            newv.splice(idx, 1);
            newv.unshift(fon);
        }

        // move wiki to the top and set sWiki
        state.sWiki = '';
        var wiki = newv.find((vir) => vir.vir.toLowerCase().includes("wiki"));
        if( wiki ) {
            var idx = newv.indexOf(wiki);
            newv.splice(idx, 1);
            newv.unshift(wiki);
            state.sWiki = wiki.url;
        }

        state.sViri = newv;

    },
    sGradivo: (state, newv) => { state.sGradivo = newv; },
    sSelvpr: (state, newv) => { 
        console.log("sSelvpr - set", newv);
        state.sSelvpr = newv;
    },
    sSelOdg: (state, newv) => { state.sSelOdg = newv; },
    sSelTocka: (state, newv) => { 
        console.log("sSelTocka - set", newv);
        state.sSelTocka = newv;
        state.sIzbTocka = newv;
        store.dispatch('loadViri', newv.id);
        //commit('sIzbTocka', newv);

    },
    sZapis: (state, newv) => { state.sZapis = newv; },

    clearTocka: (state) => { 
        console.log("clear selTocka");
        state.sSelTocka = {};
        state.sIzbTocka = {};
        store.dispatch('loadViri', 0);
        store.dispatch("loadGradivo", { vpr: state.sSelvpr, toc: "*" });
    },

    selectTockaByName(state, name) {
        console.log("selectTockaByName", name);
        var found = false;
        var mreza = state.sDetails.mreza;
        for (var i in mreza){
            var t = mreza[i];
            if(t.ime == name) {
              //commit('sSelTocka', {id:t.id, name:t.ime, desc:t.opis, x:t.x, y:t.y});
              state.sSelTocka = {id:t.id, name:t.ime, desc:t.opis, x:t.x, y:t.y};
              found = true;
              break;
            }
        }
        if(!found) {
            var vuts = this.vsetocke;
            for (var i in vuts) {
                var t = vuts[i];
                if(t.name == name) {
                  //commit('sSelTocka', {id:t.id, name:t.ime, desc:t.opis, x:t.x, y:t.y});
                  state.sSelTocka = {id:t.id, name:t.name, desc:t.place, x:t.lon, y:t.lat};
                  found = true;
                  break;
                }
            }
        }
        if(found) {
            //commit('sIzbTocka', obj);
            this.sIzbTocka = state.sSelTocka;
            //store.dispatch.setMapSelected(state.sSelTocka);
        }
    },

};

// trije koraki options ... da dobimo crftoken
// post da se prijavimo in dobimo auth token
// get vprs
const actions = {
    async loadVprs({ commit, state }) {
        const response = await diaaxi.get('vprs/');
        console.log(response);
        commit('sVprs', response.data);
        store.dispatch('loadVprDetails', state.sSelvpr); // da se naložijo podatki za prvi vpr (čeje prazen pa vsaj mreza)
    },
    async loadVprDetails({ commit, state }, pSelvpr) {
        console.log("details ", state.sSelvpr, pSelvpr);
        if( typeof pSelvpr == "string" && pSelvpr != "") {
            commit('sSelvpr', pSelvpr);
        } else {
            pSelvpr = state.sSelvpr;
        }
        //const selvpr = state.sSelvpr;
        // rabimo da dobimo mrezo tudi ko ni vprašanja
        //if( pSelvpr != "") {
            const response = await diaaxi.get('vprdetails/'+pSelvpr+'/');
            console.log(response);
            commit('sDetails', response.data);
        //}
    },
    async loadGradivo({ commit, state }, sel) {
        console.log("loadGradivo", sel);
        var vpr = "*"
        if(sel && sel.vpr) {
            if (typeof sel.vpr == "string") {
                vpr = sel.vpr.split(".")[0];
            } else if(typeof sel.vpr == "object" && sel.vpr.name) {
                vpr = sel.vpr.name.split(".")[0];
            }
        }
        var toc = "*"
        if(sel && sel.toc) {
            if (typeof sel.toc == "string") {
                toc = sel.toc;
            } else if(typeof sel.toc == "object" && sel.toc.name) {
                toc = sel.toc.name;
            }
        }

        if (toc == "*") { //} && pSelvpr == "*") {
            commit('sGradivo', []);
            return;
        }

        const response = await diaaxi.get('gradivo/'+vpr+'/'+toc+'/');
        console.log(response);
        commit('sGradivo', response.data);
    },
    async loadViri({ commit, state }, pSelTocka) {
        console.log("loadViri", pSelTocka);
        if( pSelTocka == 0) {
            commit('sViri', []);
            return;
        }

        /*
        if( typeof pSelTocka == "string" && pSelTocka != "") {
            commit('sSelTocka', pSelTocka);
        } else {
            pSelTocka = state.sSelTocka;
        }
        */
        // kljuc je TOC strzero(id,15)
        let kljuc = '' + pSelTocka;
        kljuc = 'TOC' + kljuc.padStart(15, '0');
        const response = await diaaxi.get('viri/' + kljuc + '/');
        console.log(response);
        commit('sViri', response.data);
    },
    setMapSelected({ commit, state, getters }, obj) {
        commit('sIzbTocka', obj);
        //console.log("setMapSelected", obj);
    },        
    selectOdg({ commit, state, getters, dispatch }, newv) {
        console.log('sSelOdg', newv, state.sSelOdg);
        commit('sSelOdg', newv);
        dispatch('zapis4Odg', newv);
    },
    zapis4Odg({ commit, state, getters }, newv) { 
        console.log('zapis4Odg', newv, state.sSelOdg);
        var zaps = state.sDetails.zapisi.filter(z => z.odgovor == newv);
        var zs = "";
        var ts = "";
        for(const z of zaps) {
            zs = addIfNotIn(zs, z.zapis);
            ts = addIfNotIn(ts, z.tocka);
        }
        if(zs != "") zs = "Zapisi za odgovor "+zs;
        state.sZapis = zs;

        if(ts != "") ts = "Točke za odgovor "+ts;
        state.sSelTocka = ts;
    },

};

export default {
    state,
    getters,
    mutations,
    methods: {
        getOdgId(oIme)  {
            for( var i=0; i<state.sDetails.odgs.length; i++) {
                var odg = state.sDetails.odgs[i];
                if(odg.ime == oIme) {
                    return odg.id;
                }
            }
            return 0;
        },
        getTockaId(tIme)  {
            for( var i=0; i<state.sDetails.mreza.length; i++) {
                var tocka = state.sDetails.mreza[i];
                if(tocka.ime == tIme) {
                    return tocka.id;
                }
            }
            return 0;
        }
    },
    actions,
}
