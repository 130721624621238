import { ref, computed, nextTick  } from "vue";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import WMSCapabilities from 'ol/format/WMSCapabilities.js';
import GeoJSON from "ol/format/GeoJSON";

import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";

import VectorSource from "ol/source/Vector";
import TileWMS from "ol/source/TileWMS";
import Select from "ol/interaction/Select";

import { Fill, Stroke, Style, Circle, Text, Icon } from "ol/style";
import { LineString } from "ol/geom";

import { defaults as defaultControls, ScaleLine } from "ol/control";
import * as narTC from "@/static/aux_layers/tocke.json";
import { gettext } from "@/main.js";
//import { addIfNotIn } from "./func.js";
import { addIfNotIn } from "@/store/modules/func.js";

var QGIS_SERVER = "/qgis-server?"

if (process.env.NODE_ENV === 'development') {
    QGIS_SERVER = "http://localhost:1337/qgis-server?"
}
  
if (process.env.NODE_ENV === 'production') {
    // ...
}


// map ptip in pozicija v odmik in font
const mapTipPozicija = [
  // [tip, pozicija.slax, odmik, font]
  [1,"1",[-2.6,-0.3],"Simbola,7,black"],
  [1,"2",[0,1.7],"Simbola,7,black"],
  [1,"3.3",[1.6,-1.5],"Simbola,6,black"],
  [1,"3",[2.6,-0.3],"Simbola,7,black"],
  [1,"4",[0,-2.3],"Simbola,7,black"],
  [1,"14",[-2.6,-2.3],"Simbola,7,black"],
  [1,"41",[0,-4.3],"Simbola,7,black"],
  [1,"34",[2.6,-2.3],"Simbola,7,black"],
  [1,"11",[-4.9,-0.3],"Simbola,7,black"],
  [1,"12",[-2.6,1.7],"Simbola,7,black"],
  [1,"31",[4.9,-0.3],"Simbola,7,black"],
  [1,"23",[2.6,1.7],"Simbola,7,black"],
  [1,"21",[0,3.7],"Simbola,7,black"],
  [9,"",[-5,0],"Simbola,8,black"]
]

function getTipPosition(tip, pozicija) {
  let poz = mapTipPozicija.find( (el) => el[0] == tip && el[1] == pozicija);
  if (poz == undefined) {
    poz = mapTipPozicija.find( (el) => el[0] == 9);
  }
  return poz;
}

var krogec = new Style({
  image: new Circle({
    radius: 3,
    stroke: new Stroke({
      color: "#2c3e50",
      width: 1,
    }),
  })
});

var vertical = new Style({
  // two lines
  stroke: new Stroke({
    color: "#ffcc33",
    width: 3,

  }),
});


const normFill = new Fill( {color: "#2c3e50",});
const bFill = new Fill( {color: "#0022aa33",});
const transFill = new Fill( {color: "#cccc1031",});
const normStroke =  new Stroke({color: "#fff", width: 1,});
const blueStroke =  new Stroke({color: "#00f", width: 1,});

var podlaga = new Style({
  image: new Circle({
    radius: 50,
    stroke: new Stroke({
      color: "#0000ff55",
      width: 1,
    }),
    fill: transFill,
  })
});


var textStyle = new Style({
  text: new Text({
    placement: "point",
    displacement: [0, 0],
    offsetY_: 0,
    offsetX_: 0,
    font: '12px "Arial Narrow", "Arial Unicode MS", "sans-serif"',
    //font: 'bold 11px "Open Sans", "Arial Unicode MS", "sans-serif"',
    overflow: false,
    fill: normFill,
    stroke: normStroke,
    //textBaseline: "bottom",
    padding: [4,4,4,4],
  }),
});

/**
 * Številke poz v qgis so glede na bottom base line
 * če damo textBaseline: "bottom" se prikaže ok a je v osnovi narobe
 * druga možnost je da je textBaseline: "middle" in premaknemo vse simbole za offsety_ = 0.5 (sym.offsetY_ = (tp[2][1] + 0.5) * r;)
 * 
 */
var textSelStyle = new Style({
  text: new Text({
    placement: "point",
    displacement: [0, 0],
    font: '13px "Arial Narrow", "Arial Unicode MS", "sans-serif"',
    overflow: false,
    fill: normFill,
    backgroundFill: bFill,
    stroke: normStroke,
    padding: [4,4,4,4],
  }),
});

var symbStyle = new Style({
  text: new Text({
    placement: "point",
    displacement: [0, 0],
    font: '10px "Simbola", "Arial Unicode MS", "sans-serif"',

    overflow: false,
    fill: normFill,
    stroke: normStroke,

    padding: [4,4,4,4],
  }),
});

var line = new Style({
  geometry: null,
  stroke: blueStroke,
});

function odgStyle( feature, resolution, selected=false ) {
  const iconScale = 1 / Math.pow(resolution, 1 / 2);
  const textScale = 1 / Math.pow(resolution, 1 / 2) + 0.6;

  const tocka = feature.get("tocka");
  const znak = feature.get("znak");
  const barva = feature.get("barva");
  //const font = feature.get("font");
  //if(font == undefined) font = symbStyle.getText().getFont();

  const tip = feature.get("tip");
  var poz = "" + feature.get("pozicija");
  const slax = feature.get("slax");

  // tekst
  //textStyle.getText().setText(tocka);
  const txt = textStyle.clone();
  txt.getText().setText(tocka);

  textSelStyle.getText().setText(tocka);

  // symbol
  //const d = 15;
  //var r = d; // čudno * selected?1.2:1;
  //r *= textScale;

  const d = 7;
  var r = d; // čudno * selected?1.2:1;
  //r /= iconScale;

  if(slax=="3")
    poz = "3.3"

  const tp = getTipPosition(tip, poz);
  //console.log("tp", tp, tip, poz, r, iconScale, textScale );

  const symbStyle2 = symbStyle.clone();

  const sym = symbStyle2.getText()
  sym.setText(znak);
  //sym.getStroke().setColor(barva);
  sym.setFill(new Fill({color:barva}));

  //sym.setFont(font);

  sym.offsetX_ = tp[2][0] * r;
  sym.offsetY_ = (tp[2][1] + 0.5) * r;
  //console.log("offset", sym.offsetX_, sym.offsetY_ );

  const pozs = textStyle.clone();
  pozs.getText().setText(tocka + " " + poz);
  pozs.offsetX_ = tp[2][0] * r + 20;
  pozs.offsetY_ = (tp[2][1] + 0.5) * r + 20;
  

  var rtv = [];
  if(selected) {
    podlaga.getImage().setScale(textScale);
    rtv.push(podlaga);
  }

  //rtv.push(krogec); // le vodilo
  //rtv.push(txt); //TODO premakni številko točke v izris mreže
  rtv.push(symbStyle2);

  if(false) { // two lines - muha, samo za debug coordinat
    var point = feature.getGeometry();
    var coords = point.getCoordinates();
    var r10 = 300 / iconScale;//r*10;
    var coords1 = [coords[0], coords[1] - r10];
    var coords2 = [coords[0], coords[1] + r10];
    var coords3 = [coords[0] - r10, coords[1]];
    var coords4 = [coords[0] + r10, coords[1]];

    var vline = new LineString([coords1, coords2]);
    var hline = new LineString([coords3, coords4]);
    var vertical = line.clone();
    var horizontal = line.clone();
    vertical.setGeometry(vline);
    horizontal.setGeometry(hline);
    
    rtv.push(vertical);
    rtv.push(horizontal);
  }

  //rtv.push(pozs);

  return rtv;
}          

function findFeature( vec, fid ) {
  //var vec = state.sMrezaTockeLayer;
  if(vec == null) return null;
  
  var src = vec.getSource();
  var fts = src.getFeatures();
  for(const f of fts) {
    if(f.get("FeatureID") == fid) {
      return f;
      break;
    }
  }
  return null;
}


var selectedID = 0;
function mrezaStyle( feature, resolution, selected=false ) {
  //const iconScale = 1 / Math.pow(resolution, 1 / 2);
  const textScale = 1 / Math.pow(resolution, 1 / 2) + 0.6;

  var tocka = feature.get("name");
  var mreza = feature.get("mreza");

  if(tocka == undefined) tocka = "";
  var isUser = (mreza == "user");

  if(!isUser) {
    var i = 0;
    while(i < tocka.length && tocka[i] == '0' || tocka[i] == 'T')
      i += 1;

    if(i > 0)
      tocka = tocka.substring(i);
  }

  var ts = textStyle;

  var rtv = [];
  if(selected || selectedID == feature.get("FeatureID")) {
    podlaga.getImage().setScale(textScale);
    rtv.push(podlaga);
    selectedID = feature.get("FeatureID");
  }
  if(isUser) {
    ts = textSelStyle.clone();
    //ts.getText().setScale(textScale);
  }
  ts.getText().setText(tocka);
  rtv.push(ts);

  return rtv;
}


function mrezaFeature( id, t ) {
  var f = {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [t.x, t.y]
      },
      "properties": {
        "name": t.ime,

        "FeatureID": id,
        "description": t.opis,
        "mreza": t.mreza,
      }
    }
  return f;
}



const diaserver=process.env.VUE_APP_TITLE;
const state = {
    project: '/data/podlaga.qgz',
    qgis_server: process.env.ISLA_QGISSERVER,
    ol_map: ref(null),
    layerState: ref({}),
    //styles: {},
    wms_layers: ref(''),
    map_layer: ref(null),
    sMrezaTockeSrc: ref(null),
    sMrezaTockeLayer: ref(null),
    sOdgovoriLayer: ref(null),
    sIzbTocka: ref(null),
    sNovaTocka: ref(null),
    sZapis: ref(null),
    //karta: null,
    //details: {},
};

const getters = {
    getLayerState: state => state.layerState,
    getStyles: state => state.styles,
    getLayerTocke: state => state.sMrezaTockeLayer,
    getLayerOdgovori: state => state.sOdgovoriLayer,
    getOLMap: state => state.ol_map,
    getNovaTocka: state => state.sNovaTocka,
    getIzbTocka: state => state.sIzbTocka,
    //getDetails: state => state.details,
    zapisi(state, getters, rootState, rootGetters) {
      // rootGetters.getterName
      // rootGetters['namesapce/getterName'] 
      return rootGetters['diatlas/zapisi'];
    },

  };

const actions = {

    loadBase ({ commit, state }, url) {
        if(state.map_layer != null) return; // not first run
        /*
        // save the items currently in the cart
        const savedCartItems = [...state.cart.added]
        // send out checkout request, and optimistically
        // clear the cart
        commit(types.CHECKOUT_REQUEST)
        // the shop API accepts a success callback and a failure callback
        shop.buyProducts(
          products,
          // handle success
          () => commit(types.CHECKOUT_SUCCESS),
          // handle failure
          () => commit(types.CHECKOUT_FAILURE, savedCartItems)
        )
        */
        console.log("map", this);
        state.map_layer = new TileLayer({
            id: "podlaga",
            source: new TileWMS({
              url: QGIS_SERVER,
              //url: "/qgis-server?",
              params: {
                SERVICE: "WMS",
                VERSION: "1.3.0",
                REQUEST: "GetMap",
                MAP: "/data/podlaga.qgz",
                LAYERS: [], //"slovenija_sencen_relief,narecja", //"hs125,aglomeration,hydro_polygon,hydro_line,border_polygon,border_line,frame",
                TILED: true,
                STYLES: state.wms_layers,
                FORMAT: "image/png",
                WIDTH: 230,
                HEIGHT: 230,
                CRS: "EPSG:3857",
              },
              projection: "EPSG:3857",
              attributions: [
                '&copy;<a href ="https://www.e-prostor.gov.si/">GURS</a>',
                '&copy;<a href ="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
              ],
            }),
          });


          state.sMrezaTockeLayer = new VectorLayer({
            //source: new VectorSource({
            //    features: new GeoJSON().readFeatures(narTC.default),
            //  }),
            source: new VectorSource(),
            // določeno naknadno ko je dodan v baseMap map: this.baseMap,
            style: mrezaStyle,
            //declutter: true,
          });

          function normOdgStyle(feature, resolution) {
            return odgStyle(feature, resolution, false);
          }
      
          state.sOdgovoriLayer = new VectorLayer({
            source: new VectorSource(),
            style: normOdgStyle,
            });

      
    },

    activateOdgovori({ dispatch, commit, state, rootState, rootGetters }) { // enable select interaction
        console.log("activateOdgovori");
        // create a Select interaction and add it to the map

        function selectedTockaStyle(feature, resolution) {
          var prevID = selectedID;
          selectedID = feature.get("FeatureID");
          if(prevID != selectedID) {
            // repaint last selected
            var src = state.sMrezaTockeLayer.getSource();
            //var f = src.getFeatureById(prevID);
            /*
            var f = findFeature(state.sMrezaTockeLayer, prevID)
            if(f) {
              src.removeFeature(f);
              src.addFeature(f);
            }
            */
            src.changed();
          }
          return mrezaStyle(feature, resolution, true);
        }

        var selectInteraction = new Select({
          layers: [state.sMrezaTockeLayer],
          style: selectedTockaStyle,
          onSelect: function(evt) {
            console.log("select", evt);
          }
        });

        selectInteraction.on('select', function(evt){
          var coord = evt.mapBrowserEvent.coordinate;
          var selItems = evt.selected;
          for(const f of selItems) {
            console.log("select", f, f.get("FeatureID"));
            const id = f.get("FeatureID");
            const name = f.get("name");
            const desc = f.get("description");
            const obj = {id:id, name:name, desc:desc, x:coord[0], y:coord[1]};
            if(id == -1) {
              // nima smisla, to bomo klicali ko bo zajel na roke commit('selectTocka', {id:id, name:name, desc:desc, x:coord[0], y:coord[1]});
              state.setNovaTocka = obj
            }
            state.setIzbTocka = obj;
            state.ol_map.getView().setCenter(coord);
            //state.ol_map.getView().setZoom(16);
            //state.sIzbTocka = obj;
            commit('sIzbTocka', obj);
            //state.sSelTocka = obj;
            commit('sSelTocka', obj);
            dispatch('findZapis4id', id);

            // FIXME premakni to v akcijo, da lahko kličeš od drugod

            //var zapisi = rootGetters['diatlas/zapisi'];
            var zapisi = rootState.diatlas.sDetails.zapisi

            // poiščemo še zapis za to točko
            if(zapisi != undefined) {
              //var zap = zapisi.find(zap => zap.tocka_id == id);
              // find all
              var zaps = zapisi.filter(zap => zap.tocka_id == id);
              console.log("zapisi", zapisi, zaps);
              var sz = "";
              var sodg = "";
              for(const z of zaps) {
                sz = addIfNotIn(sz, z.zapis);
                //sz += z.zapis + ", ";
                //sodg += z.odgovor + ", ";
                sodg = addIfNotIn(sodg, z.odgovor);
              }
              //if(sz != "") sz = "Zapisi za točko "+sz;
              commit('sZapis', sz, {root:true});
              //if(sodg != "") sodg = "Odgovori za točko "+sodg;
              commit('sSelOdg', sodg, {root:true});
            }
            
            break; // samo prvo
          }
          console.log("select", coord, selItems);
        });

        state.ol_map.addInteraction(selectInteraction);
    },

    findZapis4id({ commit, state, rootState, rootGetters }, id) {
      console.log("findZapis4id", id);
    },

    addLyrState({ commit, state }, name, start=true ) {
        commit('setLyrState', name, start);
    },

    selectPodlaga({ commit, state }, item ) {
        //commit('setLyrState', name, start);
        console.log("selectPodlaga",item, state.layerState.podlage[item.idx]);
        if(state.layerState.podlage[item.idx]) {
            for(const p in state.layerState.podlage) {
                if(p != item.idx && state.layerState.podlage[p])
                    commit('setPodlaga',{ime:p, naslov:state.layerState.podlage_naslov[p], val:false});
            }
        }
        commit("setWmsLayers");
    },

    selectSloj({ commit, state }, item ) {
        //commit('setLyrState', name, start);
        console.log("selectSloj", item, state.layerState.sloji[item.gidx][item.idx]);
        commit("setWmsLayers");
    },
    refreshMap({ commit, state }) {
        lyrs = '';
        layerState.podlage.forEach( (el,idx) => {
            if(el) lyrs += ","+idx;
        })
        if(lyrs.length > 0) lyrs = lyrs.substring(1); // brez vejice
        console.log("nove mape ", lyrs);
        commit("setWmsLayers", lyrs);
    },

    capabilities({ commit, state }, par) {
        if(state.wms_layers != '') return;
        console.log("capabilities");
        var xhr = new XMLHttpRequest();
        xhr.open('GET', QGIS_SERVER + 'SERVICE=WMS&VERSION=1.3.0&REQUEST=GetCapabilities');
        xhr.onload = function() {
          var parser = new WMSCapabilities();
          var capabilities = parser.read(xhr.responseText);
          //console.log('capaXXX', xhr.responseText);
          var layers = capabilities.Capability.Layer.Layer;
          //console.log('capa', layers);

          commit('setCapa', layers);

          /* List of layers is now in the `layers` array */
        };
        xhr.onerror = function(data) {
            console.log("error", data);
        }
        xhr.send();
    },

    replaceTocke({ commit, state, rootState }, mreza) { // TODO remove parameter mreza ( get it from rootState )
        //state.tocke = tocke;
        console.log("replaceTocke", mreza);
        var fs = [];
        for (var i in mreza){
        //for (const [i, t] of mreza) {
        //mreza.forEach(t => {
            var t = mreza[i];
            /*
            var f = {
                "type": "Feature",
                "geometry": {
                  "type": "Point",
                  "coordinates": [t.x, t.y]
                },
                "properties": {
                  "name": t.ime,
                    "FeatureID": i,
                    "description": t.opis,
                }
              }
            */
            fs.push( mrezaFeature(t.id, t) );
        }
        let usertocke = this.vsetocke; // iz data.js
        if(usertocke == undefined) usertocke = [];
        for( var i=0; i<usertocke.length; i++) {
          var t = usertocke[i];
          var f = {
              "type": "Feature",
              "geometry": {
                  "type": "Point",
                  "coordinates": [t.lon, t.lat]
              },
              "properties": {
                  "name": t.name,
                  "FeatureID": t.id,
                  "description": t.place,
                  "mreza": 'user',
            }
          }

          fs.push( f ); //mrezaFeature(t.id, t) );
      }

      // add show current selected location
      var na = rootState.answer.newAnswer;
      var co = [13.5,46.5]
      if(na && na.coordinates.wgs != undefined) {
        co = [na.coordinates.wgs.x, na.coordinates.wgs.y];
      }
      var f = {
        "type": "Feature",
        "geometry": {
            "type": "Point",
            "coordinates": co
        },
        "properties": {
            "name": "•", // • •¤
            "FeatureID": -1,
            // small circle in center
            "description": gettext.$gettext("Selected location"), 
            "mreza": 'prog',
        }
      }
      fs.push( f );
      
      //// dodamo točko za zajem
      //fs.push( mrezaFeature(-1, {ime:"***", x:0, y:0}) );

      //);
      var gj = {
          "type": "FeatureCollection",
          "features": fs,
      }
      commit('setGJTocke', gj);
    },

    replaceOdgovori({ commit, state }, zapisi) {
        //state.tocke = tocke;
        console.log("replaceOdgovori", zapisi);
        var fs = [];
        for (var i in zapisi){
            var t = zapisi[i];
            var f = {
                "type": "Feature",
                "geometry": {
                  "type": "Point",
                  "coordinates": [t.koordinate.x, t.koordinate.y]
                },
                "properties": {
                    "znak": t.znak,
                    "FeatureID": t.id,
                    "tocka": t.tocka,
                    "tip": t.tip,
                    "pozicija": t.pozicija,
                    "barva": t.barva,
                    "font": t.font,
                    "slax": t.slax,
                    "description": t.odgovor + "/" + t.zapis,
                }
              }
            fs.push(f);
        }
        //);
        var gj = {
            "type": "FeatureCollection",
            "features": fs,
        }
        commit('setOdgovori', gj);
    },

    XXXselectTockaByName({ commit, state, rootState }, name) {
        console.log("selectTockaByName", name);
        var found = false;
        var val = {id:-1, name:"", desc:"", x:0, y:0}
        var mreza = rootState.diatlas.sDetails.mreza;
        for (var i in mreza){
            var t = mreza[i];
            if(t.ime == name) {
              found = true;
              //commit('sSelTocka', {id:t.id, name:t.ime, desc:t.opis, x:t.x, y:t.y}, {root:true});
              val = {id:t.id, name:t.ime, desc:t.opis, x:t.x, y:t.y};
              break;
            }
        }
        if(!found) {
          var uts = this.vsetocke; // iz data.js
          for( var i=0; i<uts.length; i++) {
            var t = uts[i];
            if(t.name == name) {
              //commit('sSelTocka', {id:t.id, name:t.name, desc:t.place, x:t.lon, y:t.lat}, {root:true});
              val = {id:t.id, name:t.name, desc:t.place, x:t.lon, y:t.lat};
              found = true;
              break;
            }
          }
        }
        if(!found) commit('sSelTocka', val, {root:true});
        //if(!found) rootState.diatlas.sSelTocka = val;
      },
    selectTockaByNameNNN({ commit, state, rootState }, name) { // from layer
      console.log("selectTockaByName", name);
      //var mreza = rootState.diatlas.sMrezaTockeLayer.getSource().getFeatures();
      var mreza = state.sMrezaTockeSrc.getFeatures();
      for (var i in mreza){
        var t = mreza[i];
        if(t.get("name") == name) {
          commit('sSelTocka', {id:t.get("FeatureID"), name:t.get("name"), desc:t.get("description"), x:t.getGeometry().getCoordinates()[0], y:t.getGeometry().getCoordinates()[1]}, {root:true});
          break;
        }
      }
    },


}

const mutations = {

    setSelectedPoint(state, point) { // on map click
      console.log("setSelectedPoint", point);

      var vec = state.sMrezaTockeLayer;
      if(vec == null) return;
      
      var src = vec.getSource();
      var fts = src.getFeatures();
      for(const f of fts) {
        if(f.get("FeatureID") == -1) {
            f.getGeometry().setCoordinates(point);
            src.changed();
            break;
        }
      }
    },
    sIzbTocka: (state, tocka) => {
      console.log("sIzbTocka - mapjs", tocka);
      state.sIzbTocka = tocka;
    },

    cleanMapState: (state) => {
        state.wms_layers = '';
        state.map_layer = null;
        state.layerState = {podlage: {}, sloji:{}, podlage_naslov:{}, sloji_naslov:{}};
    },
    toggleBaseLayer: (state, id) => {
        state.layerState.podlage[id] = !state.layerState[id];
    },
    setBaseLyrState: (state, id, newstate) => {
        state.layerState.podlage[id] = newstate;
    },
    setOLMap(state, map) {
        state.ol_map = map;
    },
    setGJTocke(state, tocke) {
        //state.sMrezaTockeSrc.updateParams({features: tocke});
        if(state.sMrezaTockeLayer == null) return;

        const src = state.sMrezaTockeLayer.getSource();   
        src.clear();
        var geojson4326 = new GeoJSON({    
            dataProjection: "EPSG:4326",
            featureProjection: "EPSG:3857"
          });
        //state.sMrezaTockeSrc.addFeatures(  geojson4326.readFeatures(tocke) );
        //src.addFeatures(new GeoJSON().readFeatures(tocke));
        src.addFeatures(  geojson4326.readFeatures(tocke) );
    },

 /*    selectTockaByName(state, name) {
        console.log("selectTockaByName", name);
        var mreza = state.sDetails.mreza;
        for (var i in mreza){
            var t = mreza[i];
            if(t.ime == name) {
              state.sSelTocka = {id:t.id, name:t.ime, desc:t.opis, x:t.x, y:t.y};
              break;
            }
        }
    }, */

    selectTocka(state, tocka) { // osvežimo koordinate točke za zajem in programsko izberemo točko
        console.log("selectTocka", tocka);
        var vec = state.sMrezaTockeLayer;
        if(vec == null) return;
        
        var src = vec.getSource();
        var fts = src.getFeatures();
        for(const f of fts) {
          if(f.get("FeatureID") == tocka.id) {
            f.set("name", tocka.name);
            f.set("description", tocka.desc);
            //f.setGeometry(new Point([tocka.x, tocka.y]));
            break;
          }
        }

        const f = src.getFeatureById(tocka.id);
        if(f != null) {
            console.log("selectTocka", f);
            if(t.id == -1) {// točka za zajem
              f.properties.name = tocka.name;
              f.properties.description = tocka.desc;
              //f.coordinates = [tocka.x, tocka.y];
              src.changed();

            }

            const sel = state.ol_map.getInteractions().getArray()[0];
            sel.getFeatures().clear();
            sel.getFeatures().push(f);
            //sel.dispatchEvent({type: 'select', selected: [f]});
 
        }
    },
            /*
            const sel = state.ol_map.getInteractions().getArray()[0];
            sel.getFeatures().clear();
            sel.getFeatures().push(f);
            //sel.dispatchEvent({type: 'select', selected: [f]});
            */
            
    setOdgovori(state, tocke) {
        //state.sMrezaTockeSrc.updateParams({features: tocke});
        if(state.sOdgovoriLayer == null) return;

        const src = state.sOdgovoriLayer.getSource();   
        src.clear();
        var geojson4326 = new GeoJSON({    
            dataProjection: "EPSG:4326",
            featureProjection: "EPSG:3857"
          });
        src.addFeatures(  geojson4326.readFeatures(tocke) );
    },
    setCapa(state, layers) {
        layers.forEach(group => {
            console.log("group", group.Name);
            var firsttrue = true
            group.Layer.forEach(l => {
                console.log("l ", group.Name, l.Name);
                if(group.Name == 'Podlage') {
                    this.commit('setPodlaga', {ime:l.Name, naslov:gettext.$gettext(l.Title), val:firsttrue});
                    firsttrue = false;
                } else {
                   this.commit('addSloj', {ime:l.Name, naslov:gettext.$gettext(l.Title), group:gettext.$gettext(group.Name)});
                }
            })
          });
          console.log("layer 3", state.layerState);
          this.commit('setWmsLayers');
    },
    setWmsLayers: (state) => {
        var lyrs = '';
        const p = state.layerState.podlage
        for( const idx in p ) {
            if(p[idx]) lyrs += ","+idx;
        }
        const s = state.layerState.sloji
        for( const gidx in s ) {
            for( const idx in s[gidx] ) {
                if(s[gidx][idx]) lyrs += ","+idx;
            }
        }
        if(lyrs.length > 0) lyrs = lyrs.substring(1); // brez vejice
        console.log("nove mape ", lyrs);
        //commit("setWmsLayers", lyrs);
        
        if(state.map_layer) {
            const src = state.map_layer.getSource();
            src.updateParams({"LAYERS": lyrs});
            // old src.dispatchChangeEvent();
            src.changed();
            //map.updateSize()
        }
        
        // trigers repaint
        state.layerState.wms_layers = lyrs;
      },
    setPodlaga: (state, p) => {
        if(!state.layerState.hasOwnProperty('podlage')) {
            state.layerState['podlage'] = {};
            state.layerState['podlage_naslov'] = {};
        }
        if(!state.layerState.podlage.hasOwnProperty(p.ime)) {
            state.layerState.podlage[p.ime] = p.val;
            state.layerState.podlage_naslov[p.ime] = p.naslov;
        }
    },
    addPodlaga: (state, item) => {
        if(!state.layerState.hasOwnProperty('podlage')) {
            state.layerState['podlage'] = {};
            state.layerState['podlage_naslov'] = {};
        }
        if(!state.layerState.podlage.hasOwnProperty(item.ime)) {
            state.layerState.podlage[item.ime] = true;
            state.layerState.podlage_naslov[item.ime] = item.naslov;
        }
    },
    addSloj: (state, item) => {
        console.log("l? ", item);
        if(!state.layerState.hasOwnProperty('sloji')) {
            state.layerState['sloji'] = {};
            state.layerState['sloji_naslov'] = {};
        }
        if(!state.layerState.sloji.hasOwnProperty(item.group)) {
            state.layerState.sloji[item.group] = {};
            state.layerState.sloji_naslov[item.group] = {};
        }
        if(!state.layerState.sloji[item.group].hasOwnProperty(item.ime)) {
            state.layerState.sloji[item.group][item.ime] = false;
            state.layerState.sloji_naslov[item.group][item.ime] = item.naslov;
        }
      },
};


export default {
    state,
    getters,
    mutations,
    actions,
/*    
    methods: {
      findFeature( fid ) {
        var vec = state.sMrezaTockeLayer;
        if(vec == null) return null;
        
        var src = vec.getSource();
        var fts = src.getFeatures();
        for(const f of fts) {
          if(f.get("FeatureID") == fid) {
            return f;
            break;
          }
        }
        return null;
      },
    },    
  */
    computed: {
        //...mapState({'details': 'sDetails'}),
        ...mapGetters(['getDetails',"getNewAnswer"]),
        ...mapActions(['fetchUserTocke']),
    },
/*
    computed: mapState({
        // arrow functions can make the code very succinct!
        details: state => state.sDetails,
    
    }),

    watch: {
        getDetails(newVal) {
          console.log("new details in mapjs", newVal);
          this.details = newVal;
        },
    },
    */
    created() {
      console.log("created mapjs");
    }
    
}
