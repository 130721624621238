<template>
  <div id="App">
    <q-layout view="lHr lpr lFr">
      <q-header bordered class="bg-primary">
        <q-toolbar>
          <q-btn dense flat round icon="menu" @click="toggleLeftDrawer" />
          <q-toolbar-title> iSLA </q-toolbar-title>
          <q-space />
          <q-btn v-for="(name, key) in language.available" :key="key" @click="language.current = key"> <!-- language switch  :class="$style.button" -->
            <img :src="'/flags/' + key + '.svg'" class="flag" :alt="name"/> <!-- flag -->
          </q-btn>
          <q-btn dense flat round icon="menu" @click="toggleRightDrawer" />
        </q-toolbar>
        <q-tabs align="left">
          <q-route-tab name="map" to="/" :label='$gettext("Map")' />
          <q-route-tab name="input" to="/input" :label='$gettext("Input")' v-if="loggedIn" />
          <q-route-tab name="about" to="/about" :label='$gettext("About iSla")'  class="tab-right"/>
        </q-tabs>
      </q-header>

      <q-drawer
        v-model="leftDrawerOpen"
        side="left"
        elevated
        :breakpoint="150"
        :width="400"
      > <!-- overlay -->
        <left-drawer></left-drawer>
      </q-drawer>

      <q-drawer
        v-model="rightDrawerOpen"
        side="right"
        elevated
        :width="400"
      >
        <right-drawer></right-drawer>
      </q-drawer>

      <q-page-container class="container">
        <!-- q-slide-transition class="box">
          <q-page class="q-pa-md" v-if="tabs">
            <div class="q-gutter-y-md">
              <q-tab-panels v-model="tab2" animated>
                <q-tab-panel name="newAnswer">
                  <new-answer></new-answer>
                </q-tab-panel>

                <q-tab-panel name="reviewAnswers">

                </q-tab-panel>
              </q-tab-panels>
            </div>
          </q-page>
        </q-slide-transition -->
        <q-tab-panels v-model="tab" animated>
          <q-tab-panel name="map" class="nopad">
            <router-view/>
          </q-tab-panel>
          <q-tab-panel name="input" class="nopad">
            <router-view/>
          </q-tab-panel>
          <q-tab-panel name="about" class="nopad">
            <router-view/>
          </q-tab-panel>
        </q-tab-panels>
      </q-page-container>
      <q-footer class="bg-grey-4 text-black">
        <q-toolbar>
          <q-toolbar-title class="col-4" id="footlogos">
            <img class="" width="100px" src= "rubin_logo_z_obrobo.png" alt = "Rubin geoinformatika d.o.o." />
            <span style="font-size: x-small; text-wrap: nowrap; display:inline-block; align-self: center; margin: auto 10px"
                  class="">&copy; 2024 - Rubin geoinformatika d.o.o.</span>

            <!-- q-avatar id="footavatar" -->
            <!-- img id="isla-logo" src="favicon.png" -->
            <!-- /q-avatar -->
          </q-toolbar-title>
          <q-space />
          <q-toolbar-title class="col-3">
            <img id="aris-logo" src="aris-en.png" v-if="language.current=='en'"><img id="aris-logo" src="aris-sl.png" v-else>
          </q-toolbar-title>
          <!-- q-toolbar-title style="font-size: x-small; text-wrap: wrap;"  class="col-8" v-if="language.current=='en'">
This application has been created within the framework of the programme Slovenian language in synchronous and diachronic development (P6-0038, 1. 1. 2022–31. 12. 2027), and the project i-SLA: interactive atlas of Slovenian dialects (L6-2628 , September 1, 2020–August 31, 2024), which are co-financed by ARIS.
          </q-toolbar-title>
          <q-toolbar-title style="font-size: x-small; text-wrap: wrap;"  class="col-8" v-else>
Aplikacija je nastala v okviru programa Slovenski jezik v sinhronem in diahronem razvoju (P6-0038, 1. 1. 2022–31. 12. 2027) in projekta i-SLA – Interaktivni atlas slovenskih narečij (L6-2628, 1. 9. 2020–31. 8. 2024), ki ju sofinancira ARIS.
          </q-toolbar-title-->
        </q-toolbar>
      </q-footer>
    </q-layout>
  </div>
</template>

<script>
import { ref } from "vue";
import LeftDrawer from "@/components/LeftDrawer";
import RightDrawer from "@/components/RightDrawer";
import { mapGetters, mapMutations, mapState, mapActions } from "vuex";
import { useGettext } from "vue3-gettext";
import "./styles/isla.sass";

export default {
  name: "App",
  components: {
    LeftDrawer,
    RightDrawer,
  },
  data() {
    const language = useGettext();
    return {
      leftDrawerOpen: ref(true),
      rightDrawerOpen: ref(true),
      tab: ref("map"),
      tab2: ref("newAnswer"),
      tabs: ref(false),
      dense: ref(true),
      language
    };
  },

  computed: {
    ...mapGetters(["user", "loggedIn"]),
    ...mapState(["map_layer","wms_layers"])
  },

  watch: {
    tab() {
      this.tabs = true;
      this.changeTab(this.tab);
      console.log("set tab to", this.tab);
    },
  },

  methods: {
    ...mapMutations(["changeTab","cleanMapState"]),
    ...mapActions(["loadVprs", "loadVprDetails", "fetchVseTocke","fetchUserTocke","fetchVsePovezave","fetchVsePriloge","fetchVseZapise",
    "fetchInformantaByUser"]), 
    // , "addUserTockeToMap"


    toggleTabs() {
      this.tabs = !this.tabs;
    },

    toggleLeftDrawer() {
      this.leftDrawerOpen = !this.leftDrawerOpen;
    },

    toggleRightDrawer() {
      this.rightDrawerOpen = !this.rightDrawerOpen;
    },
  },
  async created() {
    console.log("created app");
    // some states shouldent be persistent
    this.cleanMapState();
    await this.loadVprs();
    await this.loadVprDetails();
    if (this.loggedIn) {
      await this.fetchVseTocke();
      await this.fetchUserTocke();
      await this.fetchVsePovezave();
      await this.fetchVsePriloge();
      await this.fetchVseZapise();
      await this.fetchInformantaByUser();
      //this.$store.dispatch("addUserTockeToMap");
  }

  },
  add_translations() { // never called
    $gettext("Sloji"),
    $gettext("Govori"),
    $gettext("Narečne meje"),
    $gettext("Narečne skupine"),
    $gettext("Narečja"),
    $gettext("Naselja"),
    $gettext("Senčen relief Slovenije"),
    $gettext("Open Street Map");
    $gettext("Selected location");

  }
};
</script>
<style>
#app {
  /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #102c48;
}

#footlogos {
  display: flex;
  justify-content: space-between;
}

#aris-logo{
  height: 50px;
  vertical-align: bottom;
  margin: 6px auto;
  display: inline-block;
}

#isla-logo {
  height: 50px;
  vertical-align: bottom;
  margin: 6px auto;
  display: inline-block;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

@font-face {
  font-family: Bahnschrift;
  src: url(./assets/fonts/merriweather-v28-latin-regular.woff);
}

img.flag {
  height: 24px;
}

.my-font {
  font-family: "merriweather-v28-latin-regular";
  color: #3f2c06;
}

.stack-top {
  z-index: 9;
}

.container {
  position: relative
}

.box {
  position: absolute;
  left: 53%;
  transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
}

.button {
  margin-right: 0.5rem;
  cursor: pointer;
  padding: 0.25rem 0.5rem;
}

.nopad {
  padding: 0 !important;
}

.tab-right { margin-left: auto; }

</style>