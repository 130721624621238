<template>
  <div class="q-pa-md form-bg">
    <q-form
      @submit="onLogin({ user_name: user_name, password: password })"
      @reset="onReset"
      class="q-gutter-md"
    >
      <q-input
        v-model="user_name"
        :label='$gettext("Username")'
        lazy-rules
        :rules="[
          (val) => (val && val.length > 0) || $gettext('Enter your username.'),
        ]"
        :dense="dense"
      />
      <q-input
        v-model="password"
        type="password"
        :label='$gettext("Password")'
        lazy-rules
        :rules="[
          (val) => (val && val.length > 0) || $gettext('Enter your password.'),
        ]"
        :dense="dense"
      />

      <div>
        <q-btn
          :label='$gettext("Submit")'
          type="submit"
          color="primary"

        />
        <q-btn
          :label='$gettext("Reset")'
          type="reset"
          color="primary"
          flat
          class="q-ml-sm"
        />
      </div>
    </q-form>
  </div>
</template>

<script>
import { ref } from "vue";
import { useQuasar } from "quasar";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "login",
  data() {
    return {
      user_name: ref(null),
      password: ref(null),
      dense: ref(false),
      $q: useQuasar(),
    };
  },

  methods: {
    ...mapActions(["onLogin", "msgError"]),

    displayNotification(newVal) {
      if (!newVal) {
        return;
      }
      console.log("login DN", newVal);
      this.msgError(newVal);
      /*
      this.$q.notify({
        position: "center",
        color: "negative",
        timeout: 10000,
        icon: "report_problem",
        message: newVal,
      });
      */
    },

    onReset() {
      this.user_name = null;
      this.password = null;
    },
  },

  computed: {
    ...mapGetters(["loginError"]),
  },

  watch: {
    loginError(newVal) {
      this.displayNotification(newVal.error);
    },
  },
};
</script>