<template>
  <!-- q-separator></q-separator -->
  <div class="q-gutter-md">
    <q-list>
      <q-expansion-item group="account-settings">
        <template v-slot:header>
          <q-item-section avatar>
            <q-icon name="alternate_email_icon"> </q-icon>
          </q-item-section>
          <q-item-section>{{ $gettext("Change email address") }}</q-item-section>
        </template>
        <q-list>
          <q-item class="background-col">
            <q-form @submit="submitNewEmail(email)" class="q-gutter-md">
              <p>{{ user.email }}</p>
              <q-input
                v-model="email"
                type="email"
                :label='$gettext("New email address")'
                lazy-rules
                :rules="[
                  (val) => (val && val.length > 0) || 'This field is required.',
                  isValidEmail,
                ]"
                :dense="dense"
              />

              <div>
                <q-btn
                  square
                  :label='$gettext("Submit")'
                  type="submit"
                  color="primary"
                  class="q-mr-xs"
                />
              </div>
            </q-form>
          </q-item>
        </q-list>
      </q-expansion-item>
      <q-separator />
      <q-expansion-item group="account-settings">
        <template v-slot:header>
          <q-item-section avatar>
            <q-icon name="password"> </q-icon>
          </q-item-section>
          <q-item-section>{{ $gettext("Change password") }}</q-item-section>
        </template>
        <q-list>
          <q-item class="background-col">
            <q-form
              @submit="
                submitNewPassword({
                  old_password: oldPassword,
                  new_password: newPassword,
                })
              "
              @reset="onResetPass()"
              class="q-gutter-md"
            >
              <q-input
                v-model="oldPassword"
                type="password"
                :label='$gettext("Current password")'
                lazy-rules
                :rules="[
                  (val) =>
                    (val && val.length > 0) ||
                    $gettext('Type in your current password.'),
                ]"
                :dense="dense"
              />
              <q-input
                v-model="newPassword"
                type="password"
                :label='$gettext("New password")'
                lazy-rules
                :rules="[
                  (val) =>
                    (val && val.length > 0) ||
                    $gettext('Please type in your new password.'),
                ]"
                :dense="dense"
              />
              <q-input
                v-model="newPasswordOnceMore"
                type="password"
                :label='$gettext("New password again")'
                lazy-rules
                :rules="[
                  (val) =>
                    (val && val.length > 0) ||
                    $gettext('Please type in your new password again.'),
                  (val) => val == newPassword || $gettext('Passwords do not look the same.'),
                ]"
                :dense="dense"
              />

              <div>
                <q-btn
                  square
                  :label='$gettext("Submit")'
                  type="submit"
                  color="primary"
                  class="q-mr-xs"
                />
                <q-btn
                  :label='$gettext("Reset")'
                  type="reset"
                  color="primary"
                  flat
                  class="q-ml-sm"
                />
              </div>
            </q-form>
          </q-item>
        </q-list>
      </q-expansion-item>
      <q-separator />
      <q-expansion-item group="account-settings">
        <template v-slot:header>
          <q-item-section avatar>
            <q-icon name="delete"> </q-icon>
          </q-item-section>
          <q-item-section>{{ $gettext("Delete account") }}</q-item-section>
        </template>
        <q-list>
          <q-item class="background-col">
            <q-form @submit="deleteAccount" class="q-gutter-md">
              <q-input
                v-model="oldPassword"
                type="password"
                :label='$gettext("Password")'
                lazy-rules
                :rules="[
                  (val) => (val && val.length > 0) || $gettext('Enter your password.'),
                ]"
                :dense="dense"
              />
              <div>
                <q-btn
                  square
                  :label='$gettext("Submit")'
                  type="submit"
                  color="primary"
                  class="q-mr-xs"
                />
              </div>
            </q-form>
          </q-item>
          <q-item>
            <q-item-section>
              <q-item-label><q-icon name="warning"> </q-icon> {{ $gettext("Warning") }}</q-item-label>
              <q-item-label caption>{{ $gettext("This action is irreversible.") }}</q-item-label>
            </q-item-section>
          </q-item>
          <q-item>
            <q-item-section>
              <q-item-label><q-icon name="info"> </q-icon> {{ $gettext("Info") }}</q-item-label>
              <q-item-label caption>
                {{ $gettext("All your data will be deleted from the system.") }}
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-item>
            <q-item-section>
              <q-item-label><q-icon name="delete"> </q-icon> {{ $gettext("Delete account") }}</q-item-label>
              <q-item-label caption>
                {{ $gettext("If you are sure, please type in your password and click submit.") }}
              </q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </q-expansion-item>
    </q-list>
  </div>
</template>

<script>
import { ref } from "vue";
import { mapGetters, mapActions } from "vuex";
import { useQuasar } from "quasar";

export default {
  name: "AccountSettings",

  data() {
    return {
      email: ref(null),
      oldPassword: ref(null),
      newPassword: ref(null),
      newPasswordOnceMore: ref(null),
      language: ref(null),
      $q: useQuasar(),
      dense: ref(false),
    };
  },

  computed: {
    ...mapGetters(["user","submitNewEmailError", "submitNewPasswordError","submitNotImplementedError", "submitDeleteAccountError"]),
  },

  watch: {
    submitNewEmailError(newVal) {
      this.displayNotification(newVal.error);
    },
    submitNewPasswordError(newVal) {
      this.displayNotification(newVal.error);
    },
    submitNotImplementedError(newVal) {
      this.displayNotification(newVal.error);
    },
    submitDeleteAccountError(newVal) {
      this.displayNotification(newVal.error);
    },
  },

  methods: {
    ...mapActions(["logout", "submitNewEmail", "submitNewPassword", "deleteAccount", "msgError"]),
    

    displayNotification(newVal) {
      console.log("account DN", newVal);
      this.msgError(newVal);
      /*
      this.$q.notify({
        position: "center",
        color: "warning",
        message: newVal,
      });
      */
    },

    isValidEmail(email) {
      const emailPattern =
        /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
      return emailPattern.test(email) || $gettext("This does not look correct.");
    },

    isSamePassword(oldPassword, newPassword) {
      if (oldPassword !== newPassword) {
        return $gettext("Passwords do not look the same.");
      }
    },

    onResetPass() {
      this.oldPassword = ref(null);
      this.newPassword = ref(null);
      this.newPasswordOnceMore = ref(null);
    }
  },
};
</script>